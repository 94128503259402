import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';
import { isOverflown } from '@libs/domUtils';
import Tooltip from '@libComponents/Tooltip';

const propTypes = {
    color: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    separator: PropTypes.bool
};

const defaultProps = {
    styles: {},
    separator: false
};

const styles = ({ spacing, palette, breakpoints }) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing.unit * 1.5
    },
    colorBox: {
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5,
        marginRight: spacing.unit
    },
    value: {
        marginRight: spacing.unit
    },
    name: {
        maxWidth: spacing.unit * 12.5,
        color: palette.secondary.main,
        margin: 0,
        fontFamily: 'Museo',
        fontWeight: 400,
        fontSize: spacing.unit * 1.7,
        lineHeight: 1.5,
        [breakpoints.up(770)]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    separator: {
        width: '2px',
        height: spacing.unit * 2.5,
        backgroundColor: palette.grey[300],
        margin: `0 ${spacing.unit * 1.5}px`
    }
});

const LegendItem = ({
    classes,
    color,
    name,
    value,
    styles,
    separator,
    ...rest
}) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const nameRef = useRef(null);
    const c = composeClsx({ classes, styles });

    const handleMouseOver = () => {
        if (nameRef.current) {
            const isOver = isOverflown(nameRef.current);
            setTooltipVisible(isOver);
        }
    };

    const renderName = (
        <p className={c.name} ref={nameRef}>
            {name}
        </p>
    );

    const renderTooltip = () => <Tooltip title={name}>{renderName}</Tooltip>;

    return (
        <div
            className={c.container}
            onMouseOver={handleMouseOver}
            tabIndex={0}
            {...rest}
        >
            <div className={c.colorBox} style={{ backgroundColor: color }} />
            <Typography className={c.value} variant='body2'>
                {value}
            </Typography>
            {tooltipVisible ? renderTooltip() : renderName}
            {separator && <div className={c.separator} />}
        </div>
    );
};

LegendItem.propTypes = propTypes;
LegendItem.defaultProps = defaultProps;

const _LegendItem = withStyles(styles)(LegendItem);

export { _LegendItem as default, _LegendItem as LegendItem };
