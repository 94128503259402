import { SPECIAL_FINISHES } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import {
    LAUNDRY_STATUS,
    STOCKPILE_STATUS,
    CONSENT_STATUS,
    SEAL_SAMPLE_TYPES,
    SEAL_SAMPLE_STATUS
} from '../redux.datasets';
import { DOMAIN_NAME } from '../common';

export const DATASETS = [
    SPECIAL_FINISHES,
    LAUNDRY_STATUS,
    STOCKPILE_STATUS,
    CONSENT_STATUS,
    SEAL_SAMPLE_TYPES,
    SEAL_SAMPLE_STATUS
];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number'
        },
        {
            name: 'qcRef',
            header: 'Reference',
            type: 'text',
            filterable: true
        },
        {
            name: 'qcVersion',
            header: 'Version No',
            type: 'text'
        },
        {
            name: 'jwpNo',
            header: 'JWP No',
            type: 'text',
            filterable: true
        },
        {
            name: 'styleRef',
            header: 'Style Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'styleDescription',
            header: 'Style Description',
            type: 'text',
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'color',
            header: 'Color',
            type: 'text',
            filterable: true
        },
        {
            name: 'qcDate',
            header: 'QC Date',
            type: 'date',
            filterable: true
        },
        {
            name: 'sealByDate',
            header: 'Seal By Date',
            type: 'date',
            filterable: true
        },
        {
            name: 'orderFactory',
            header: 'Order Factory',
            type: 'text',
            filterable: true
        },
        {
            name: 'orderQty',
            header: 'Order Qty',
            type: 'number',
            filterable: true
        },
        {
            name: 'finishWashId',
            header: 'Finish/Wash',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: SPECIAL_FINISHES,
            filterable: true
        },
        {
            name: 'fabricBatch',
            header: 'Fabric Batch',
            type: 'text'
        },
        {
            name: 'factoryProductionUnit',
            header: 'Factory Production Unit',
            type: 'text',

            filterable: true
        },
        {
            name: 'preWashPanelStatusId',
            header: 'Pre Wash Panel Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: LAUNDRY_STATUS,
            filterable: true
        },
        {
            name: 'bodyFabricStatusId',
            header: 'Body Fabric Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: STOCKPILE_STATUS,
            filterable: true
        },
        {
            name: 'trimFabricStatusId',
            header: 'Trim Fabric Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: STOCKPILE_STATUS,
            filterable: true
        },
        {
            name: 'accTrimStatus',
            header: 'Acc Trim Status',
            type: 'text'
        },
        {
            name: 'missingTrims',
            header: 'Missing Trims',
            type: 'text'
        },
        {
            name: 'embellishmentStatusId',
            header: 'Embellishment Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: STOCKPILE_STATUS,
            filterable: true
        },
        {
            name: 'washColorStatusId',
            header: 'Wash Color Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: STOCKPILE_STATUS,
            filterable: true
        },
        {
            name: 'stylingStitchingStatusId',
            header: 'Styling Stitching Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: CONSENT_STATUS,
            filterable: true
        },
        {
            name: 'washShrinkageLength',
            header: 'Wash Shrinkage Length',
            type: 'text'
        },
        {
            name: 'washShrinkageWidth',
            header: 'Wash Shrinkage Width',
            type: 'text'
        },
        {
            name: 'printShrinkageLength',
            header: 'Print Shrinkage Length',
            type: 'text'
        },
        {
            name: 'printShrinkageWidth',
            header: 'Print Shrinkage Width',
            type: 'text'
        },
        {
            name: 'spirality',
            header: 'Spirality',
            type: 'text'
        },
        {
            name: 'foundGsm',
            header: 'Found GSM',
            type: 'number'
        },
        {
            name: 'patternStatusId',
            header: 'Pattern Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: CONSENT_STATUS,
            filterable: true
        },
        {
            name: 'fitStatusId',
            header: 'Fit Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: CONSENT_STATUS,
            filterable: true
        },
        {
            name: 'sampleTypeId',
            header: 'Sample Type',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: SEAL_SAMPLE_TYPES,
            filterable: true
        },
        {
            name: 'frontImage',
            header: 'Front Image',
            type: 'image'
        },
        {
            name: 'backImage',
            header: 'Back Image',
            type: 'image'
        },
        {
            name: 'clarificationFromBuyer',
            header: 'Clarification From Buyer',
            type: 'richText'
        },
        {
            name: 'generalInstruction',
            header: 'General Instruction',
            type: 'richText'
        },
        {
            name: 'sealVerification',
            header: 'Seal Verification',
            type: 'richText'
        },
        {
            name: 'workmanshipComments',
            header: 'Workmanship Comments',
            type: 'richText'
        },
        {
            name: 'measurementComments',
            header: 'Measurement Comments',
            type: 'richText'
        },
        {
            name: 'preventiveAction',
            header: 'Preventive Action',
            type: 'richText'
        },
        {
            name: 'fabricCTQ',
            header: 'Fabric CTQ',
            type: 'richText'
        },
        {
            name: 'embellishmentCTQ',
            header: 'Embellishment CTQ',
            type: 'richText'
        },
        {
            name: 'accTrimCTQ',
            header: 'Acc & Trims CTQ',
            type: 'richText'
        },
        {
            name: 'cprtWashCTQ',
            header: 'CPRT & Wash CTQ',
            type: 'richText'
        },
        {
            name: 'cuttingCTQ',
            header: 'Cutting CTQ',
            type: 'richText'
        },
        {
            name: 'finishingPackingCTQ',
            header: 'Finishing & Packing CTQ',
            type: 'richText'
        },
        {
            name: 'sewingCTQ',
            header: 'Sewing CTQ',
            type: 'richText'
        },
        {
            name: 'bulkProductionComplicacy',
            header: 'Bulk Production Complicacy',
            type: 'richText'
        },
        {
            name: 'testCuttingQty',
            header: 'Test Cutting Qty',
            type: 'number',
            filterable: true
        },
        {
            name: 'sampleStatusId',
            header: 'Sample Status',
            type: 'select',
            domain: DOMAIN_NAME,
            entity: SEAL_SAMPLE_STATUS,
            filterable: true
        },
        {
            name: 'rejectQty',
            header: 'Reject Qty',
            type: 'number',
            filterable: true
        },
        {
            name: 'dhu',
            header: 'DHU',
            type: 'number',
            render: ({ column, row }) =>
                row[column.name] ? `${row[column.name]}%` : '',
            filterable: true
        },
        {
            name: 'tdr',
            header: 'TDR',
            type: 'number',
            render: ({ column, row }) =>
                row[column.name] ? `${row[column.name]}%` : '',
            filterable: true
        },
        {
            name: 'remarks',
            header: 'Remarks',
            type: 'richText'
        }
    ];
};
