import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'classnames';

import { formatDateToShort } from '@baseComponents/Date/normalizeDate';
import Tooltip from '@libComponents/Tooltip';

import Editor from './Editor';
import Image from './Image';
import GridCellContent from '../../../../GridCellContent';
import CustomCellRenderer from './CustomCellRenderer';
import RichText from './RichText';

const styles = ({ spacing }) => ({
    gridBodyCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: `${spacing.unit * 0.5}px ${spacing.unit}px`,
        transition: 'background-color 0.3s'
    },
    gridBodyCellYellow: {
        backgroundColor: 'yellow'
    },
    gridBodyCheckboxCell: {
        padding: spacing.unit * 0.5
    }
});

const isDateTypeColumn = type => type === 'date';
const isSelectTypeColumn = type => type === 'select';
const isImageTypeColumn = type => type === 'image';
const isRichTextTypeColumn = type => type === 'richText';

const Cell = ({
    classes: c,
    column,
    row,
    isColumnCheckbox,
    toggleRowSelection,
    isRowSelected,
    syncGridDataWithServerAsync,
    updateRowsInGrid,
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    highlightChangeLog,
    ...props
}) => {
    const [cellEditMode, setCellEditMode] = useState(false);
    const previousVersion = row.previousVersion;

    const handleCellDoubleClick = useCallback(() => {
        if (column.editable) {
            setCellEditMode(true);
        }
    }, [column.editable]);

    const handleCellEditModeOff = useCallback(() => {
        setCellEditMode(false);
    }, []);

    const renderContent = () => {
        if (isColumnCheckbox) {
            const checkboxProps = {
                handleChange: () => toggleRowSelection(row.id),
                checked: isRowSelected,
                styles: { root: c.gridBodyCheckboxCell }
            };
            return column.renderCheckbox(checkboxProps);
        }

        if (isImageTypeColumn(column.type)) {
            return <Image column={column} row={row} />;
        }

        if (column.editable && column.isCellEditable(row) && cellEditMode) {
            return (
                <Editor
                    column={column}
                    row={row}
                    datasetSelector={datasetSelector}
                    datasetLoadingSelector={datasetLoadingSelector}
                    updateRowsInGrid={updateRowsInGrid}
                    handleCellEditModeOff={handleCellEditModeOff}
                    syncGridDataWithServerAsync={syncGridDataWithServerAsync}
                />
            );
        }

        if (column.render) {
            return <CustomCellRenderer row={row} column={column} />;
        }

        if (isDateTypeColumn(column.type)) {
            return (
                <GridCellContent
                    content={formatDateToShort(row[column.name])}
                />
            );
        }

        if (isSelectTypeColumn(column.type)) {
            return (
                <GridCellContent
                    content={valueSelector(
                        column.domain,
                        column.entity,
                        row[column.name]
                    )}
                />
            );
        }

        if (isRichTextTypeColumn(column.type)) {
            return (
                <RichText
                    text={row[column.name]}
                    textToBeDisplayed={column.textToBeDisplayed}
                    columnHeader={column.header}
                />
            );
        }

        return <GridCellContent content={row[column.name]} />;
    };

    const getPreviousContent = () => {
        if (isDateTypeColumn(column.type)) {
            return formatDateToShort(previousVersion[column.name]);
        }

        if (isSelectTypeColumn(column.type)) {
            return valueSelector(
                column.domain,
                column.entity,
                previousVersion[column.name]
            );
        }

        return previousVersion[column.name];
    };

    const hasChanged =
        highlightChangeLog &&
        previousVersion &&
        column.highlightable &&
        row[column.name] !== previousVersion[column.name];

    const cellContent = (
        <div
            className={clsx(c.gridBodyCell, hasChanged && c.gridBodyCellYellow)}
            onDoubleClick={handleCellDoubleClick}
            {...props}
        >
            {renderContent()}
        </div>
    );

    return hasChanged && !cellEditMode ? (
        <Tooltip title={`Previous: ${getPreviousContent()}`}>
            {cellContent}
        </Tooltip>
    ) : (
        cellContent
    );
};

export default withStyles(styles)(Cell);
