import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, CircularProgress } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import isBlobUrl from '@libs/isBlobUrl';
import { withModalProvider, withModal } from '@libComponents/Modal';
import {
    datasetLoadingSelector,
    createFetchGridPreviewData,
    gridPreviewDataSelector
} from '@libs/datasets';
import { setSnack } from 'utilities/redux.actions';
import { Iframe } from 'common/components';

import Content from 'orders/Order/components/Report/PDFContent';
import ActionsAdornment from './ActionsAdornment';
import PreviewMailModal from './PreviewMailModal';
import { CP_GRID_PREVIEW_DATA } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';
import { orderGridPreviewDataSelector } from 'orders/redux.selectors';
import { useReport } from 'orders/hooks';
import { loadProps } from 'orders/Order/components/Report/hooks';

const styles = () => ({
    container: {
        width: '85vw',
        minHeight: '90vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    loader: {
        color: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
        top: '50%'
    }
});

const mapStateToProps = (state, { orderNo }) => {
    const data = gridPreviewDataSelector(
        state,
        DOMAIN_NAME,
        CP_GRID_PREVIEW_DATA
    );
    const {
        emailSubject,
        attachments,
        storedPDFData,
        previewTitle,
        orderVersionNo,
        ...PDFProps
    } = orderGridPreviewDataSelector(data, orderNo);
    return {
        emailSubject,
        attachments,
        storedPDFData,
        previewTitle,
        orderVersionNo,
        retailer: data.retailerId,
        PDFProps,
        loading: datasetLoadingSelector(
            state,
            DOMAIN_NAME,
            CP_GRID_PREVIEW_DATA
        )
    };
};

const mapDispatchToProps = (dispatch, { orderNo }) => ({
    fetchGridPreviewData: () =>
        dispatch(
            createFetchGridPreviewData({
                endpoint: 'OrderMains/GetOrderDetailsListByOrderNo',
                domain: DOMAIN_NAME,
                entity: CP_GRID_PREVIEW_DATA
            })({ orderNo })
        ),
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const PDFPreview = compose(
    withModalProvider,
    withModal({
        handleModal: PreviewMailModal
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(
    ({
        classes,
        onClose,
        orderNo,
        emailSubject,
        storedPDFData,
        previewTitle,
        orderVersionNo,
        retailer,
        PDFProps,
        loading,
        fetchGridPreviewData,
        setSnack,
        handleModal
    }) => {
        const [PDFData, setPDFData] = useState({});

        const [
            PDFBlobURLGenerator,
            PDFBlobURLObject,
            handlePDFBlobURLRemove
        ] = useReport({
            fileName: 'order_report.pdf',
            document: Content,
            props: PDFProps,
            loadProps,
            setSnack
        });

        const handlePreviewModalClose = useCallback(() => {
            handlePDFBlobURLRemove(); // re initialize report obj
            if (isBlobUrl(PDFData.url)) {
                // clear from memory
                URL.revokeObjectURL(PDFData.url);
            }
            setTimeout(() => onClose(), 0);
        }, [PDFData]);

        const handleMailModal = useCallback(() => {
            return handleModal({
                emailSubject,
                retailer,
                PDFData,
                reference: orderNo,
                handlePreviewModalClose,
                orderVersionNo
            });
        }, [PDFData]);

        useEffect(() => {
            if (!loading && !PDFData.url && !!Object.keys(PDFProps).length) {
                PDFBlobURLGenerator();
            }
        }, [PDFProps, loading, PDFData]);

        useEffect(() => {
            if (!loading && storedPDFData) {
                setPDFData(storedPDFData);
            }
        }, [storedPDFData, loading]);

        useEffect(() => {
            if (PDFBlobURLObject && PDFBlobURLObject.url) {
                setPDFData(PDFBlobURLObject);
            }
        }, [PDFBlobURLObject]);

        useEffect(() => {
            fetchGridPreviewData();
        }, []);

        return (
            <ContentContainer
                title='Order Preview'
                AdornmentComponent={
                    <ActionsAdornment
                        loading={loading || !PDFData.url}
                        handleMailModal={handleMailModal}
                        handlePreviewModalClose={handlePreviewModalClose}
                    />
                }
                styles={{ container: classes.container }}
            >
                {!loading && PDFData.url ? (
                    <Iframe src={PDFData.url} tilte={previewTitle} />
                ) : (
                    <CircularProgress size='50px' className={classes.loader} />
                )}
            </ContentContainer>
        );
    }
);

export default PDFPreview;
