import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import { GridCellContent } from 'dataGrid/components';

import DispatchItemsModal from './DispatchItemsModal';

const propTypes = {
    items: PropTypes.array.isRequired
};

const styles = ({ palette, typography }) => ({
    gridLinkCell: {
        cursor: 'pointer',
        color: palette.primary.main,
        fontWeight: typography.fontWeightSemi,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const DispatchItems = withStyles(styles)(({ classes, ...props }) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleClick = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    return (
        <>
            <GridCellContent
                content='See Items'
                onClick={handleClick}
                className={classes.gridLinkCell}
            />
            {modalOpen && (
                <DispatchItemsModal onModalClose={handleClose} {...props} />
            )}
        </>
    );
});

DispatchItems.propTypes = propTypes;

export default DispatchItems;
