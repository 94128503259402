import {
    ROUTE_TO_SEAL_SAMPLE,
    DESTROY_SEAL_SAMPLE_FORM,
    SEAL_SAMPLE_PUBLISH,
    SET_SEAL_SAMPLE_COLOR_CHILDREN_LOADING,
    SEAL_SAMPLE_REF_GENERATE,
    SET_SEAL_SAMPLE_REF_GENERATE_LOADING,
    GET_SEAL_SAMPLE_DATA,
    SET_SEAL_SAMPLE_MAIL_RECEIVER,
    SET_SEAL_SAMPLE_MAIL_BODY,
    GET_COPY_SEAL_SAMPLE_DATA,
    REDIRECT_TO_SS_AND_FETCH_REF_DATA,
    SET_SEAL_SAMPLE_EXPORT_AS_EXCEL_LOADING
} from './redux.actionTypes';

export const routeToPreproduction = mode => ({
    type: ROUTE_TO_SEAL_SAMPLE,
    payload: { mode }
});

export const destroySealSampleForm = () => ({
    type: DESTROY_SEAL_SAMPLE_FORM
});

export const sealSampleSaveDraft = ({ mode }) => ({
    type: SEAL_SAMPLE_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});

export const sealSampleHandover = ({ handleComplete, ...payload }) => ({
    type: SEAL_SAMPLE_PUBLISH,
    meta: { saveDraft: true, handover: true, handleComplete },
    payload
});

export const sealSamplePublish = ({ handleComplete, ...payload }) => ({
    type: SEAL_SAMPLE_PUBLISH,
    meta: { handleComplete },
    payload
});

export const setQCColorChildrenLoading = payload => ({
    type: SET_SEAL_SAMPLE_COLOR_CHILDREN_LOADING,
    payload
});

export const sealSampleRefGenerate = () => ({
    type: SEAL_SAMPLE_REF_GENERATE
});

export const setPreproductionReferenceLoading = payload => ({
    type: SET_SEAL_SAMPLE_REF_GENERATE_LOADING,
    payload
});

export const getPreproductionQCData = reference => ({
    type: GET_SEAL_SAMPLE_DATA,
    payload: reference
});

export const setQCMailReceiver = groupId => ({
    type: SET_SEAL_SAMPLE_MAIL_RECEIVER,
    payload: groupId
});

export const setQCMailBody = groupId => ({
    type: SET_SEAL_SAMPLE_MAIL_BODY,
    payload: groupId
});

export const getCopyPreproductionQCData = qcReference => ({
    type: GET_COPY_SEAL_SAMPLE_DATA,
    payload: qcReference
});
export const redirectToPPAndFetchRefData = (qcReference, routeId) => ({
    type: REDIRECT_TO_SS_AND_FETCH_REF_DATA,
    payload: { qcReference, routeId }
});

export const setSealSampleExportAsExcelLoading = loading => ({
    type: SET_SEAL_SAMPLE_EXPORT_AS_EXCEL_LOADING,
    payload: loading
});
