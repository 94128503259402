import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';
import clsx from 'classnames';

import { withStyles, Grid } from '@material-ui/core';

import { AttachmentDetails } from 'components';
import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Input, Editor, Date as DateInput } from '@formComponents';
import { fetchDatasets } from '@libs/datasets';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { setReloadAction } from 'utilities/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';

import { FORM_NAME } from '../../common';
import BrandingDetails from './BrandingDetails';
import BDCommission from './BDCommission';
import OtherCommission from './OtherCommission';
import { costBasedOnSelector, isAsosSelector } from 'orders/redux.selectors';
import SelectCostBasedOn from './SelectCostBasedOn';
import SelectPackingPackType from './SelectPackingPackType';
import SelectBoxType from './SelectBoxType';
import OrderComment from './OrderComment';
import HeaderAdornment from '../HeaderAdornment';

const styles = ({ spacing }) => ({
    wrapper: {
        marginBottom: spacing.unit * 4
    },
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 4
    },
    subContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    packingContent: {
        width: '90%'
    },
    spaceTop: {
        marginTop: spacing.unit * 2
    },
    commentsContent: {
        width: '80%'
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 40.75,
            fontSize: spacing.unit * 1.5,
            fontFamily: 'Museo',
            lineHeight: 1.4
        }
    }
});

const mapInputs = {
    attachments: ({ payload }) => {
        const shouldPrompt = payload.some(
            attachment =>
                attachment.hasOwnProperty('url') && isBlobUrl(attachment.url)
        );

        const nonBlobAttachments = payload.filter(
            attachment =>
                !(attachment.hasOwnProperty('url') && isBlobUrl(attachment.url))
        );
        const action = change(FORM_NAME, 'attachments', nonBlobAttachments);
        return setReloadAction({ field: 'attachments', shouldPrompt, action });
    }
};

const mapState = state => ({
    costBasedOn: costBasedOnSelector(state),
    isAsos: isAsosSelector(state)
});
const mapDispatch = {
    fetchDatasets
};

const Other = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, costBasedOn, isAsos, fetchDatasets }) => {
    useEffect(() => {
        fetchDatasets(DEVELOPMENT_TYPES);
    }, []);

    return (
        <div className={c.wrapper}>
            <ContentContainer
                title='Other information'
                AdornmentComponent={<HeaderAdornment />}
            >
                <Grid container className={c.container}>
                    <GridItem md={7}>
                        <BrandingDetails />
                    </GridItem>
                    <GridItem md={5}>
                        <AttachmentDetails label='' />
                    </GridItem>
                </Grid>
                <ContentContainer
                    title={`Packing & Cost Info`}
                    styles={{ container: c.subContainer }}
                >
                    <Grid
                        container
                        className={clsx(c.container, c.packingContent)}
                    >
                        <GridItem md={3}>
                            <Input
                                name='packingDepartmentNo'
                                label='Department No'
                            />
                        </GridItem>
                        {isAsos && (
                            <GridItem md={3}>
                                <Input
                                    name='asosDepartment'
                                    label='ASOS Dept. Name'
                                    required={true}
                                />
                            </GridItem>
                        )}
                        {!isAsos && (
                            <GridItem md={3}>
                                <Input name='packingSection' label='Section' />
                            </GridItem>
                        )}
                        <GridItem md={3}>
                            <SelectPackingPackType />
                        </GridItem>
                        <GridItem md={3}>
                            <SelectBoxType />
                        </GridItem>
                        <GridItem md={3}>
                            <DateInput
                                name='costDated'
                                label='Cost Dated'
                                placeholder='Cost Dated'
                            />
                        </GridItem>
                        <GridItem md={3}>
                            <SelectCostBasedOn data={costBasedOn} />
                        </GridItem>
                        <GridItem md={3}>
                            <Input name='htsCode' label='HS Code' />
                        </GridItem>
                        <GridItem md={3}>
                            <BDCommission />
                        </GridItem>
                        {!isAsos && (
                            <GridItem md={3}>
                                <OtherCommission />
                            </GridItem>
                        )}
                    </Grid>
                </ContentContainer>
                <ContentContainer
                    title='Comments'
                    styles={{ container: c.subContainer }}
                >
                    <Grid container className={c.container}>
                        <GridItem md={6} className={c.spaceTop}>
                            <OrderComment />
                        </GridItem>
                        <GridItem md={6}>
                            <Editor
                                name='specialComments'
                                label='Special Product Comments'
                                placeholder='Write comments here'
                                styles={{ editor: c.editor }}
                            />
                        </GridItem>
                    </Grid>
                </ContentContainer>
            </ContentContainer>
        </div>
    );
});

export default Other;
