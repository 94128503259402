import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing, palette, shape }) => ({
    imageInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: shape.borderRadius
    },
    imageName: {
        maxWidth: spacing.unit * 48,
        lineHeight: 'initial',
        marginRight: spacing.unit * 4,
        fontSize: spacing.unit * 1.75,
        color: palette.grey[600]
    },
    imageNumber: {
        fontSize: spacing.unit * 2,
        color: palette.grey[600],
        fontWeight: 'bold'
    }
});

const ImageInfo = ({ imageName, currentIndex, totalImages, classes }) => (
    <div className={classes.imageInfo}>
        <span className={classes.imageName}>{imageName}</span>
        <span className={classes.imageNumber}>
            {currentIndex + 1} / {totalImages}
        </span>
    </div>
);

export default withStyles(styles)(ImageInfo);
