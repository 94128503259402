import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { withStyles, FormControl } from '@material-ui/core';

import selectStyles from '@baseComponents/Select/SelectBase/styles';
import SelectBase from '@baseComponents/Select/SelectBase';
import { root as cancellableRoot } from '@baseComponents/Select/SelectBase/SelectCancellableIcon/styles';

const propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.array.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    handleOnBlur: PropTypes.func
};

const defaultProps = {
    isCancellable: true,
    form: 'miscellaneous'
};

const styles = ({ spacing, palette, shape }) => ({
    container: {
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: palette.common.transparent,
        borderRadius: shape.borderRadius,
        // InputBase
        '& > div': {
            marginTop: 0,
            height: 'inherit'
        },
        '&:hover': {
            borderColor: palette.grey[400]
        }
    },
    cancellableRoot: {
        ...cancellableRoot({ spacing, palette }),
        display: 'none'
    }
});

const Select = withStyles(styles)(
    class extends Component {
        state = {
            unitHeight: null,
            unitWidth: null,
            iconRight: null
        };

        componentDidMount() {
            const node = node => ReactDOM.findDOMNode(node);
            this.setState({
                unitWidth: node(this.containerRef).offsetWidth,
                unitHeight: node(this.containerRef).offsetHeight,
                iconRight: window
                    .getComputedStyle(node(this.iconRef))
                    .right.slice(0, -2)
            });
        }

        renderSelect = () => {
            const { state, props } = this;
            const { unitHeight, unitWidth, iconRight } = state;
            const {
                classes,
                listMaxNumber = 5,
                name,
                handleOnChange,
                handleOnBlur,
                value,
                form,
                ...selectProps
            } = props;

            const onChange = event => {
                let newValue = event;
                if (
                    typeof event === 'object' &&
                    event.target &&
                    event.target.value
                ) {
                    newValue = event.target.value;
                }

                if (newValue !== value) {
                    handleOnChange(newValue);
                }
            };

            const SelectComponent = withStyles(
                selectStyles({
                    listMaxNumber,
                    unitHeight,
                    unitWidth,
                    iconRight
                })
            )(SelectBase);

            return (
                unitHeight && (
                    <SelectComponent
                        {...selectProps}
                        meta={{
                            form,
                            initial: value
                        }}
                        input={{
                            name,
                            value: value || '',
                            onChange,
                            onFocus: f => f,
                            onBlur: !!handleOnBlur ? handleOnBlur : f => f
                        }}
                    />
                )
            );
        };

        render() {
            const { state, props, renderSelect } = this;
            const { iconRight, unitHeight } = state;
            const {
                classes: { container, cancellableRoot },
                className,
                styles = {}
            } = props;

            const containerClassName = classNames(
                container,
                styles.control || className
            );

            const iconClassName = classNames(
                cancellableRoot,
                styles.cancellableRoot
            );

            return (
                <Fragment>
                    {Boolean(iconRight) || (
                        <div
                            className={iconClassName}
                            ref={ref => (this.iconRef = ref)}
                        />
                    )}
                    {Boolean(unitHeight) || (
                        <FormControl
                            className={containerClassName}
                            ref={ref => (this.containerRef = ref)}
                        />
                    )}

                    {renderSelect()}
                </Fragment>
            );
        }
    }
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
