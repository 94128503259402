import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import {
    IconButton,
    withStyles,
    Menu,
    MenuItem,
    Divider
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import useGridContext from '../../../../useGridContext';

const propTypes = {
    column: PropTypes.object.isRequired
};

const styles = ({ spacing, shape }) => ({
    gridHeaderMenuButton: {
        padding: spacing.unit * 0.5,
        borderRadius: shape.borderRadius * 0.5
    },
    gridHeaderMenuButtonIcon: {
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    gridHeaderMenuDivider: {
        width: '100%',
        marginTop: spacing.unit * 0.5,
        marginBottom: spacing.unit * 0.5
    }
});

const SortableMenuItems = ({
    column,
    sortedColumn,
    sortDirection,
    sortColumnAscending,
    sortColumnDescending,
    handleAction,
    className
}) => {
    if (!column.sortable) return null;

    const isColumnSortedAsc =
        sortedColumn === column.name && sortDirection === 'asc';
    const isColumnSortedDesc =
        sortedColumn === column.name && sortDirection === 'desc';

    return (
        <>
            <MenuItem
                disabled={isColumnSortedAsc}
                onClick={handleAction(sortColumnAscending)}
            >
                Sort ascending
            </MenuItem>
            <MenuItem
                disabled={isColumnSortedDesc}
                onClick={handleAction(sortColumnDescending)}
            >
                Sort descending
            </MenuItem>
            <Divider className={className} />
        </>
    );
};

const HeaderMenuIcon = withStyles(styles)(
    ({ classes: c, column, className }) => {
        const {
            freezeColumn,
            unfreezeColumn,
            isColumnFrozen,
            hideColumn,
            sortDirection,
            sortedColumn,
            sortColumnAscending,
            sortColumnDescending
        } = useGridContext();

        const headerMenuID = 'grid-header-menu';
        const [menuAnchorEl, setMenuAnchorEl] = useState(null);

        const isFrozen = useMemo(() => isColumnFrozen(column.name), [
            isColumnFrozen,
            column.name
        ]);

        const stopDefault = useCallback(e => {
            e.preventDefault();
            e.stopPropagation();
        });

        const handleMenuClick = useCallback(e => {
            stopDefault(e);
            setMenuAnchorEl(e.currentTarget);
        }, []);

        const handleMenuClose = useCallback(e => {
            stopDefault(e);
            setMenuAnchorEl(null);
        }, []);

        const handleAction = useCallback(
            action => e => {
                handleMenuClose(e);
                action(column.name);
            },
            [handleMenuClose, column.name]
        );

        return (
            <>
                <IconButton
                    aria-owns={menuAnchorEl ? headerMenuID : undefined}
                    aria-haspopup='true'
                    className={clsx(c.gridHeaderMenuButton, className)}
                    onClick={handleMenuClick}
                >
                    <MoreVert className={c.gridHeaderMenuButtonIcon} />
                </IconButton>
                <Menu
                    id={headerMenuID}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                    onClick={stopDefault}
                >
                    <SortableMenuItems
                        column={column}
                        sortedColumn={sortedColumn}
                        sortDirection={sortDirection}
                        sortColumnAscending={sortColumnAscending}
                        sortColumnDescending={sortColumnDescending}
                        handleAction={handleAction}
                        className={c.gridHeaderMenuDivider}
                    />

                    {column.freezable && (
                        <>
                            <MenuItem
                                disabled={isFrozen}
                                onClick={handleAction(freezeColumn)}
                            >
                                Freeze Column
                            </MenuItem>
                            <MenuItem
                                disabled={!isFrozen}
                                onClick={handleAction(unfreezeColumn)}
                            >
                                Unfreeze Column
                            </MenuItem>
                            {column.hideable && (
                                <Divider className={c.gridHeaderMenuDivider} />
                            )}
                        </>
                    )}

                    {column.hideable && (
                        <MenuItem onClick={handleAction(hideColumn)}>
                            Hide Column
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    }
);
HeaderMenuIcon.propTypes = propTypes;

export default HeaderMenuIcon;
