import React from 'react';

import SealSample from './SealSample';
import SealSampleView from './SealSampleView';

import {
    NEW_SEAL_SAMPLE_ROUTE_ID,
    INCOMPLETE_SEAL_SAMPLE_ROUTE_ID,
    AMEND_SEAL_SAMPLE_ROUTE_ID
} from './SealSample/common';
import { SEAL_SAMPLE_VIEW_ROUTE_ID } from './SealSampleView/common';

export default {
    [NEW_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample />,
    [INCOMPLETE_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample mode='incomplete' />,
    [AMEND_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample mode='amend' />,
    [SEAL_SAMPLE_VIEW_ROUTE_ID]: () => <SealSampleView />
};
