import React, { useMemo } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@libComponents/Table';

const DispatchItemsTable = ({ data }) => {
    if (!data || data.length === 0) return <div>No data available</div>;

    const headerMapper = useMemo(
        () => ({
            no: 'No.',
            dispatchType: 'Dispatch Type',
            sampleRef: 'Sample Reference',
            sampleDevNo: 'Sample Dev No.',
            sampleDescription: 'Sample Description',
            dispatchQuantity: 'Dispatch Quantity',
            dispatchSize: 'Dispatch Size',
            fabricType: 'Fabric Type',
            fabricComposition: 'Fabric Composition',
            fabricGsm: 'Fabric GSM',
            colour: 'Colour',
            factory: 'Factory',
            department: 'Department',
            gender: 'Gender',
            pos: 'POS',
            remarks: 'Remarks',
            fabricGroup: 'Fabric Group',
            season: 'Season',
            designer: 'Designer'
        }),
        []
    );

    const headers = useMemo(() => Object.keys(headerMapper), [headerMapper]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(header => (
                        <TableCell key={header}>
                            {headerMapper[header]}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, rowIndex) => (
                    <TableRow key={row.detailsId || rowIndex}>
                        {headers.map(header => (
                            <TableCell key={header}>
                                {header === 'no'
                                    ? rowIndex + 1
                                    : row[header] || '-'}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default DispatchItemsTable;
