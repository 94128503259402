import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import useGridRowMutation from '@libHooks/useGridRowMutation';
import {
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets,
    createFetchGridDataset,
    gridDatasetSelector
} from '@libs/datasets';
import { userIdSelector } from 'auth/redux.selectors';
import { setSnack } from 'utilities/redux.actions';

import DataGrid from 'dataGrid';
import { GridConfirmDialog } from 'dataGrid/components';
import { dataGridSettingsSelector } from 'personalizationSettings/redux.selectors';
import { setDataGridSettings } from 'personalizationSettings/redux.actions';

import mutation from './mutation';
import { ExportOptions } from './components';
import { DISPATCH_GRID_DATA } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';
import { MODULE_NAME } from './common';
import { sampleDispatchDatasets, getColumns } from './columns';

const mapState = state => ({
    storedDataGridSettings: dataGridSettingsSelector(state, MODULE_NAME),
    datasetSelector: (domain, entity) => datasetSelector(state, domain, entity),
    datasetLoadingSelector: (domain, entity) =>
        datasetLoadingSelector(state, domain, entity),
    valueSelector: (domain, entity, value) =>
        valueSelector(state, domain, entity, value),
    dataGridLoading: datasetLoadingSelector(
        state,
        DOMAIN_NAME,
        DISPATCH_GRID_DATA
    ),
    userId: userIdSelector(state),
    ...gridDatasetSelector(state, DOMAIN_NAME, DISPATCH_GRID_DATA)
});

const mapDispatchToProps = dispatch => ({
    fetchGridData: payload =>
        dispatch(
            createFetchGridDataset({
                endpoint: 'SampleDispatches/GetSampleDispatchDetailsViewData',
                domain: DOMAIN_NAME,
                entity: DISPATCH_GRID_DATA
            })(payload)
        ),
    fetchDatasets: () => dispatch(fetchDatasets(sampleDispatchDatasets)),
    setDataGridSettings: payload =>
        dispatch(setDataGridSettings(MODULE_NAME)(payload)),
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const SampleDispatchView = connect(
    mapState,
    mapDispatchToProps
)(({ fetchDatasets, valueSelector, userId, setSnack, ...rest }) => {
    const [promiseArguments, setPromiseArguments] = useState(null);
    const [rowUpdating, setRowUpdating] = useState(false);
    const mutateRow = useGridRowMutation({
        endpoint: 'SampleDispatches/SampleDispatchUpdate',
        userId,
        promiseArguments,
        setPromiseArguments,
        setSnack
    });
    const columns = useMemo(() => getColumns(), []);
    const editableColumns = useMemo(
        () => columns.filter(column => column.editable),
        [columns]
    );
    const computeMutation = useMemo(
        () => mutation(editableColumns, valueSelector),
        [editableColumns, valueSelector]
    );
    const syncGridDataWithServerAsync = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                const { mutationText, ...rest } = computeMutation(
                    newRow,
                    oldRow
                );
                if (mutationText) {
                    // Save the arguments to resolve or reject the promise later
                    setPromiseArguments({
                        resolve,
                        reject,
                        newRow,
                        oldRow,
                        ...rest
                    });
                } else {
                    resolve(oldRow); // Nothing was changed
                }
            }),
        [computeMutation]
    );
    const handleNo = useCallback(() => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    }, [promiseArguments]);

    const handleYes = useCallback(async () => {
        setRowUpdating(true);
        await mutateRow();
        setRowUpdating(false);
    }, [mutateRow]);

    useEffect(() => {
        fetchDatasets();
    }, []);

    const renderConfirmDialog = () => {
        if (!promiseArguments) {
            return null;
        }
        const { newRow, oldRow } = promiseArguments;
        const { mutationText } = computeMutation(newRow, oldRow);
        return (
            <GridConfirmDialog
                open
                onClose={handleNo}
                onConfirm={handleYes}
                mutationText={mutationText}
                loading={rowUpdating}
            />
        );
    };
    return (
        <>
            {renderConfirmDialog()}
            <DataGrid
                columns={columns}
                syncGridDataWithServerAsync={syncGridDataWithServerAsync}
                RenderDataGridExportOptions={ExportOptions}
                valueSelector={valueSelector}
                highlightChangeLog
                {...rest}
            />
        </>
    );
});

export default SampleDispatchView;
