import { select, takeLatest, put, call, delay, fork } from 'redux-saga/effects';
import {
    change,
    formValueSelector,
    initialize,
    getFormValues,
    destroy
} from 'redux-form';

import {
    normalizeDate,
    denormalizeDate
} from '@baseComponents/Date/normalizeDate';
import apiRequest from '@libs/apiRequest';
import { splice } from '@libs/object';
import { extractIndexFromAsterisk } from '@libs/extractValue';
import isBlobUrl from '@libs/isBlobUrl';
import { isFalsyButNotZero } from '@libs/number';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { isRedirectedSelector } from 'navigation/redux.selectors';
import { normalizeInputGroup } from 'common/redux.normalizers';
import { setLoading } from 'utilities/redux.actions';
import { setSnack } from 'utilities/redux.actions';

// Domain
import {
    FORM_NAME,
    PO_FORM_NAME,
    INITIAL_VALUES,
    NEW_ORDER_ROUTE_ID,
    AMEND_ORDER_ROUTE_ID,
    INCOMPLETE_ORDER_ROUTE_ID,
    PO_INITIAL_VALUES,
    MAX_PACKS
} from 'orders/Order/common';
import {
    RESET_SAMPLES_REFS,
    ORDER_PUBLISH,
    SET_CLIENT_PO_TBC_CHILDREN,
    FETCH_PRODUCT_DETAILS_BY_REFERENCE,
    FETCH_SAMPLE_REF_CHANGE_DATA,
    ORDER_REFERENCE_GENERATE,
    SET_FACTORY_CHILDREN,
    SET_PO_LAB_DIP_COLOR_CHILDREN,
    SET_PO_TBC_CHILDREN,
    SET_PO_PACK_TBC_CHILDREN,
    SET_PO_SIZE_SET_CHILDREN,
    SET_PACK_TYPE_CHILDREN,
    RESET_SIZE_DETAILS_ON_QUANITY_OR_RATIO_CHANGE,
    WATCH_PO_REMOVED,
    SET_ORDER_BASIC_TRIMS_CHILDREN,
    SET_ORDER_NOTE_CHILDREN,
    ROUTE_TO_ORDER,
    DESTROY_ORDER_FORM,
    GET_AMEND_ORDER_DATA,
    GET_INCOMPLETE_ORDER_DATA,
    SET_PO_NO_CHILDREN,
    SET_PO_EX_FTY_DATE,
    SET_SAME_STYLE_IN_ALL_PACKS,
    GENERATE_PO_SIZE_SKU,
    ORDER_REPORT_MAIL_SEND,
    SET_PO_SHIP_MODE_CHILDREN,
    SET_SAMPLE_REFS_AMEND,
    SET_PO_SIZE_TABLE_QUANTITY_CHILDREN,
    SET_PO_SIZE_TABLE_RATIO_CHILDREN,
    RESET_ORDER_FORM
} from 'orders/redux.actionTypes';
import {
    setProductsLoading,
    setSampleChangeDataLoading,
    setFactoryRefLoading,
    setPOLabDipColorLoading,
    setPOTBCLoading,
    setPOPackTBCLoading,
    setPOSizeSetLoading,
    setOrderTrimsLoading,
    destroyOrderForm,
    setClientPOTBCLoading,
    setPaymentAndSettlementDefaultValueLoading
} from 'orders/redux.actions';
import {
    normalizeSizeDetails,
    normalizePODetails,
    normalizeLicensor
} from 'orders/redux.normalizers';
import {
    isNinetyPercentSelector,
    presetCommentsSelector,
    isAsosSelector
} from 'orders/redux.selectors';
import { PRIMARK_ID, NEWLOOK_ID, FACTORY_ORIGIN, ASOS_ID } from 'orders/common';

import orderPublishWorker from './orderPublishWorker';
import generateOrderReferenceWorker from './generateOrderReferenceWorker';
import getReferenceDataWorker from './getReferenceDataWorker';
import orderReportUploadAndMailSendWorker from './orderReportUploadAndMailSendWorker';
import orderCPSagas from './orderCPSagas';

// Workers
const resetSampleRefsWorker = function*({ meta: { fieldName } }) {
    if (fieldName === 'noOfPacks') {
        yield delay(300);
    }

    const noOfPacks = yield select(formValueSelector(FORM_NAME), 'noOfPacks');

    const actualPacks =
        noOfPacks <= 0 ? 1 : noOfPacks > MAX_PACKS ? 1 : noOfPacks;
    const newSampleRefs = new Array(actualPacks).fill(null);
    yield put(change(FORM_NAME, `sampleRefs`, newSampleRefs));
    yield put(change(FORM_NAME, `hasSameStyle`, false));
};

const setSameStyleInAllPacksWorker = function*({ payload: hasSameStyle }) {
    yield new Promise(res => setTimeout(() => res(), 0));
    const { sampleRefs = [], noOfPacks = 1 } = yield select(
        formValueSelector(FORM_NAME),
        'sampleRefs',
        'noOfPacks'
    );
    if (hasSameStyle) {
        const setSameStyleInAllPacks = new Array(noOfPacks).fill(sampleRefs[0]);
        yield put(change(FORM_NAME, `sampleRefs`, setSameStyleInAllPacks));
    } else {
        sampleRefs.fill(null, 1);
        yield put(change(FORM_NAME, `sampleRefs`, sampleRefs));
    }
};

const setSampleRefsAmendWorker = function*() {
    yield put(change(FORM_NAME, 'shouldSampleRefChangeApiCall', true));
};

const fetchProductDetailsWorker = function*() {
    const sampleRefs = yield select(formValueSelector(FORM_NAME), 'sampleRefs');
    // Do api call to get products
    try {
        yield put(setProductsLoading(true));

        const { data: { data = [] } = {} } = yield call(apiRequest, {
            url: 'Sample/GetSampleDetailsBySampleRef',
            params: { sampleRef: sampleRefs.toString(',') }
        });
        yield put(change(FORM_NAME, 'products', data));
        yield put(change(FORM_NAME, 'licensor', normalizeLicensor(data)));
        yield put(setProductsLoading(false));
    } catch (error) {
        yield put(setProductsLoading(false));
    }
};
const fetchSampleRefChangeDataWorker = function*(action) {
    const { sampleRefs, retailer, echoOrderNo } = yield select(
        formValueSelector(FORM_NAME),
        'sampleRefs',
        'retailer',
        'echoOrderNo'
    );

    try {
        yield put(setSampleChangeDataLoading(true));
        const { data = [] } = yield call(apiRequest, {
            method: 'put',
            url: 'OrderMains/OrderSampleUpdate',
            data: { orderNo: echoOrderNo, sampleRef: sampleRefs }
        });

        const { orderSamples, orderPoDetails } = data[0];

        yield put(change(FORM_NAME, 'products', orderSamples));
        yield put(
            change(FORM_NAME, 'licensor', normalizeLicensor(orderSamples))
        );
        yield put(
            change(
                FORM_NAME,
                'purchaseOrders',
                normalizePODetails(orderPoDetails, retailer)
            )
        );

        yield put(setSampleChangeDataLoading(false));
        yield put(change(FORM_NAME, 'shouldSampleRefChangeApiCall', false));
        yield put(change(FORM_NAME, 'isSampleAmend', true));
    } catch (error) {
        yield put(
            setSnack({
                message: `Sample ref update failed.`,
                type: 'error',
                duration: 8000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
        yield put(setSampleChangeDataLoading(false));
    }
};
const setClientPOTBCChildrenWorker = function*({ payload: tbcChecked }) {
    yield put(change(FORM_NAME, 'clientPONo', ''));
    if (tbcChecked) {
        try {
            yield put(setClientPOTBCLoading(true));
            const { data: clientPONo } = yield call(apiRequest, {
                url: 'OrderMains/GetTbcClientPo'
            });
            yield put(change(FORM_NAME, 'clientPONo', clientPONo));
            yield put(setClientPOTBCLoading(false));
        } catch (error) {
            yield put(setClientPOTBCLoading(false));
        }
    }
};

const setPOShipModeChildrenWorker = function*({ payload: modeId }) {
    yield put(change(PO_FORM_NAME, 'paymentTerms', ''));
    yield put(change(PO_FORM_NAME, 'settlementDiscount', ''));
    if (modeId) {
        try {
            const retailerId = yield select(
                formValueSelector(FORM_NAME),
                'retailer'
            );
            yield put(setPaymentAndSettlementDefaultValueLoading(true));
            const {
                data: {
                    data: [{ paymentTermsId, settlementDiscountId } = {}] = []
                } = {}
            } = yield call(apiRequest, {
                url: 'paymentTerms/GetpaymentTermsDefaultValue',
                params: { retailerId, modeId }
            });
            yield put(change(PO_FORM_NAME, 'paymentTerms', paymentTermsId));
            if (Number(retailerId) === ASOS_ID) {
                yield put(
                    change(
                        PO_FORM_NAME,
                        'settlementDiscount',
                        settlementDiscountId
                    )
                );
            }
            yield put(setPaymentAndSettlementDefaultValueLoading(false));
        } catch (error) {
            yield put(setPaymentAndSettlementDefaultValueLoading(false));
        }
    }
};

const setFactoryChildrenWorker = function*({ payload: retailerFactoryId }) {
    const isAsos = yield select(isAsosSelector);
    const orderNo = yield select(formValueSelector(FORM_NAME), 'echoOrderNo');
    yield put(change(FORM_NAME, 'factoryRef', ''));
    yield put(change(FORM_NAME, 'factorySl', ''));
    yield put(change(FORM_NAME, 'orderSupplierNo', ''));
    yield put(change(FORM_NAME, 'factoryOrigin', ''));
    yield put(change(FORM_NAME, 'BDCommission', ''));

    if (retailerFactoryId) {
        try {
            yield put(setFactoryRefLoading(true));
            const { data: { data = [] } = {} } = yield call(apiRequest, {
                url: 'OrderMains/GetFactoryRef',
                params: { factoryId: retailerFactoryId, orderNo }
            });
            const {
                orderFactoryRef,
                factorySl,
                orderSupplierNo,
                country,
                factoryId
            } = data[0] || {};
            yield put(change(FORM_NAME, 'factoryRef', orderFactoryRef));
            yield put(change(FORM_NAME, 'factorySl', factorySl));
            if (!isAsos) {
                yield put(change(FORM_NAME, 'supplierNo', orderSupplierNo));
            }
            yield put(change(FORM_NAME, 'factoryOrigin', country));
            // 1: Echotex, 8: Echonits
            if ([1, 8].includes(factoryId)) {
                yield put(
                    change(FORM_NAME, 'BDCommission', {
                        selectedValue: 'PC',
                        inputValue: 0.03
                    })
                );
            }
            yield put(setFactoryRefLoading(false));
        } catch (error) {
            yield put(setFactoryRefLoading(false));
        }
    }
};

const setPOLabDipColorChildrenWorker = function*({
    payload,
    meta: { field } = {}
}) {
    const dipIndex = extractIndexFromAsterisk(field);
    const isDipFirstItem = dipIndex === 0;
    const isNinetyPercent = yield select(isNinetyPercentSelector);

    if (isDipFirstItem && isNinetyPercent) {
        yield put(change(PO_FORM_NAME, 'dips[0].echoPrice.inputValue', ''));
        const allValues = yield select(getFormValues(PO_FORM_NAME));
        const initialValues = {
            ...allValues,
            estimateFactoryDate: '',
            freeOnBoardDate: '',
            shipDestination: '',
            shipMode: '',
            orderType: '',
            packType: '',
            orderQuantity: '',
            retailPrice: '',
            totalRatio: '',
            sizeSet: '',
            sizeDetails: []
        };
        yield put(initialize(PO_FORM_NAME, initialValues));
    }

    if (payload && isNinetyPercent && isDipFirstItem) {
        const retailer = yield select(formValueSelector(FORM_NAME), 'retailer');
        const dips = yield select(formValueSelector(PO_FORM_NAME), 'dips');
        const { sampleRef, batchConfirmation } = dips[dipIndex];

        const params = {
            retailerId: retailer,
            isFirst: isDipFirstItem,
            colorId: payload,
            batchRef: batchConfirmation,
            sampleRef
        };

        const loadingKey = `${field}-loading`;
        try {
            yield put(
                setPOLabDipColorLoading({
                    [loadingKey]: true
                })
            );
            const { data: { data = [] } = {} } = yield call(apiRequest, {
                url: 'Colours/GetColourDetails',
                params
            });

            const {
                exFtyDate,
                fobDate,
                retailPrice,
                echoPrice,
                tableSizeData,
                poPackType,
                ...values
            } = data[0] || {};

            const nestedNormalizedData = {
                ...values,
                estimateFactoryDate: normalizeDate(exFtyDate),
                freeOnBoardDate: normalizeDate(fobDate),
                ...(!!retailPrice
                    ? {
                          retailPrice: normalizeInputGroup(retailPrice)
                      }
                    : {}),
                ...(!!echoPrice
                    ? {
                          echoPrice: normalizeInputGroup(echoPrice)
                      }
                    : {}),
                sizeDetails: normalizeSizeDetails(
                    tableSizeData,
                    poPackType,
                    isNinetyPercent
                ),
                poPackType
            };
            const allValues = yield select(getFormValues(PO_FORM_NAME));
            const {
                echoPrice: normalizedEchoPrice,
                ...normalizedData
            } = splice(nestedNormalizedData, {
                echoPrice: 'echoPrice',
                estimateFactoryDate: 'estimateFactoryDate',
                freeOnBoardDate: 'freeOnBoardDate',
                poDestinationName: 'shipDestination',
                poModeName: 'shipMode',
                poTypeName: 'orderType',
                poPackType: 'packType',
                quantity: 'orderQuantity',
                retailPrice: 'retailPrice',
                sizeSetId: 'sizeSet',
                sizeDetails: 'sizeDetails'
            });

            yield put(
                change(PO_FORM_NAME, 'dips[0].echoPrice', normalizedEchoPrice)
            );

            yield put(
                initialize(PO_FORM_NAME, {
                    ...allValues,
                    ...normalizedData
                })
            );

            yield put(
                setPOLabDipColorLoading({
                    [loadingKey]: false
                })
            );
        } catch (error) {
            yield put(
                setPOLabDipColorLoading({
                    [loadingKey]: false
                })
            );
        }
    }
};
const setPOTBCChildrenWorker = function*({ payload: tbcChecked }) {
    yield put(change(PO_FORM_NAME, 'poNo', ''));
    yield put(change(PO_FORM_NAME, 'packTBC', false));

    if (tbcChecked) {
        try {
            yield put(setPOTBCLoading(true));
            const { data: poNo } = yield call(apiRequest, {
                url: 'PoDetails/GetTbcPo'
            });
            yield put(change(PO_FORM_NAME, 'poNo', poNo));
            yield put(setPOTBCLoading(false));
        } catch (error) {
            yield put(setPOTBCLoading(false));
        }
    }
};

const setPOPackTBCChildrenWorker = function*({ payload: tbcChecked }) {
    yield put(change(PO_FORM_NAME, 'poPackId', ''));
    if (tbcChecked) {
        try {
            yield put(setPOPackTBCLoading(true));
            const { data: poPackId } = yield call(apiRequest, {
                url: 'PoDetails/GetTbcPack'
            });
            yield put(change(PO_FORM_NAME, 'poPackId', poPackId));
            yield put(setPOPackTBCLoading(false));
        } catch (error) {
            yield put(setPOPackTBCLoading(false));
        }
    }
};

const setPONOChildrenWorker = function*({ payload: poNo }) {
    yield delay(500);
    yield put(change(PO_FORM_NAME, 'poPackId', ''));

    /*  For Primark, when they give po no, we need to extract first char of it 
    and based on first char we need to fill up following values automatically 
    for destination and retail price
    */
    const { retailer, factoryOrigin } = yield select(
        formValueSelector(FORM_NAME),
        'retailer',
        'factoryOrigin'
    );

    if (poNo && Number(retailer) === PRIMARK_ID) {
        const firstChar = poNo.charAt(0).toUpperCase();
        const DESTINATION_AND_RETAIL_CURRENCY = {
            A: { destination: 7, retailCurrency: '$' }, // Destination USA
            B: { destination: 4, retailCurrency: '£' }, // Destination UK
            C: { destination: 6, retailCurrency: '€' }, // Destination CZECH REPUBLIC (BOR)
            D: { destination: 13, retailCurrency: '€' }, // Destination IRELAND (ROI)
            S: { destination: 20, retailCurrency: '€' }, // Destination SPAIN (IB)
            N: { destination: 46, retailCurrency: '€' }, // Destination NETHERLAND (ROO)
            G: { destination: 5, retailCurrency: '€' }, // Destination GERMANY (NE)
            T: { destination: 39, retailCurrency: '$' }, // Destination TBC
            Z: { destination: 4, retailCurrency: '£' }, // Destination UK,
            F: { destination: 7, retailCurrency: '$' } // Destination USA
        };
        const prevRetailPrice = yield select(
            formValueSelector(PO_FORM_NAME),
            'retailPrice'
        );
        const {
            retailPrice: { selectedValue }
        } = PO_INITIAL_VALUES(factoryOrigin);
        const { destination = '', retailCurrency = selectedValue } =
            DESTINATION_AND_RETAIL_CURRENCY[firstChar] || {};
        yield put(change(PO_FORM_NAME, 'shipDestination', destination));
        yield put(
            change(PO_FORM_NAME, 'retailPrice', {
                selectedValue: retailCurrency,
                inputValue: prevRetailPrice.inputValue
            })
        );
    }
};

const setPackTypeChildrenWorker = function*() {
    yield put(change(PO_FORM_NAME, 'totalRatio', ''));
    yield put(change(PO_FORM_NAME, 'sizeSet', ''));
};

const setPOSizeSetChildrenWorker = function*({ payload: sizeSetId }) {
    yield put(change(PO_FORM_NAME, 'sizeDetails', []));
    if (sizeSetId) {
        const {
            retailer: retailerId,
            department: departmentId,
            batchConfirmation: batchRef
        } = yield select(
            formValueSelector(FORM_NAME),
            'retailer',
            'department',
            'batchConfirmation'
        );
        const isNinetyPercent = yield select(isNinetyPercentSelector);
        const packType = yield select(
            formValueSelector(PO_FORM_NAME),
            'packType'
        );
        try {
            let params = { retailerId, departmentId, sizeSetId };

            if (isNinetyPercent) {
                const { sampleRef, color: colorId } = yield select(
                    formValueSelector('dip-form-1'),
                    'sampleRef',
                    'color'
                ) || {};
                params = { ...params, batchRef, sampleRef, colorId };
            }
            yield put(setPOSizeSetLoading(true));
            const { data: { data = [] } = {} } = yield call(apiRequest, {
                url: 'PoSizes/GetPoSizesBySizeId',
                params
            });
            const sizeDetails = normalizeSizeDetails(
                data,
                packType,
                isNinetyPercent
            );
            yield put(change(PO_FORM_NAME, 'sizeDetails', sizeDetails));
            yield put(setPOSizeSetLoading(false));
        } catch (error) {
            yield put(setPOSizeSetLoading(false));
        }
    }
};

const resetSizeDetailsOnQuantityAndRatioChangeWorker = function*({
    meta: { isRatioChange }
}) {
    yield delay(500);

    const { retailer, noOfPacks } = yield select(
        formValueSelector(FORM_NAME),
        'retailer',
        'noOfPacks'
    );

    const {
        sizeDetails,
        totalRatio,
        orderQuantity,
        packType,
        orderStyle,
        prevOrderStyle
    } = yield select(
        formValueSelector(PO_FORM_NAME),
        'sizeDetails',
        'totalRatio',
        'orderQuantity',
        'packType',
        'orderStyle',
        'prevOrderStyle'
    );

    if (sizeDetails && sizeDetails.length > 0) {
        if (totalRatio && !isRatioChange) {
            if (orderQuantity > 0) {
                const ratioQuantity = orderQuantity / totalRatio;
                if (ratioQuantity % 1 === 0) {
                    const newSizeDetails = sizeDetails.map(
                        ({ ratio, packRatio, barCode, ...size }) => {
                            return {
                                ...size,
                                quantity: ratio * ratioQuantity,
                                packQuantity: ratio * ratioQuantity * noOfPacks,
                                ratio: ratio,
                                packRatio
                            };
                        }
                    );
                    yield put(
                        change(PO_FORM_NAME, 'sizeDetails', newSizeDetails)
                    );
                }
            }
        } else {
            const isAsos = ASOS_ID === Number(retailer);
            const newSizeDetails = sizeDetails.map(
                ({ ratio, packRatio, barCode, sku, ...size }) => {
                    return {
                        ...size,
                        quantity: 0,
                        packQuantity: 0,
                        sku: isAsos && orderStyle === prevOrderStyle ? sku : '',
                        ...(packType === 'Ratio'
                            ? { ratio: 0, packRatio: 0 }
                            : {}),
                        ...(barCode ? { barCode: '' } : {})
                    };
                }
            );
            yield put(change(PO_FORM_NAME, 'sizeDetails', newSizeDetails));
        }
    }
};

/**
 * watchPORemovedWorker
 * * When users delete a po from po's array they can't do softpublish.
 * * also we removing the po from last payload so that no of po remain
 * * same in both payload.
 *
 */

const watchPORemovedWorker = function*({ payload: index, meta: { mode } }) {
    if (mode === 'amend' && index >= 0) {
        yield put(change(FORM_NAME, 'isPORemoved', true));
        const lastPublishPayload = yield select(
            formValueSelector(FORM_NAME),
            'lastFullPublishedPayload'
        );
        const { poDetails = [], ...lastPayload } = lastPublishPayload || {};
        const poDetailsCopy = [...poDetails];
        poDetailsCopy.splice(index, 1);
        if (!!poDetailsCopy.length) {
            yield put(
                change(FORM_NAME, 'lastFullPublishedPayload', {
                    ...lastPayload,
                    poDetails: poDetailsCopy
                })
            );
        }
    }
};

const setPOSizeTableQuantityChildrenWorker = function*({
    payload,
    meta: { field }
}) {
    const payloadIndex = extractIndexFromAsterisk(field);
    const noOfPacks = yield select(formValueSelector(FORM_NAME), 'noOfPacks');
    const sizeDetails = yield select(
        formValueSelector(PO_FORM_NAME),
        'sizeDetails'
    );
    const isNinetyPercent = yield select(isNinetyPercentSelector);

    const currentPackQuantity = sizeDetails[payloadIndex].packQuantity;
    const updatedPackQuantity = payload * noOfPacks;

    if (currentPackQuantity !== updatedPackQuantity) {
        const updatedSizeDetails = sizeDetails.map((size, index) => {
            if (index === payloadIndex) {
                return {
                    ...size,
                    packQuantity: updatedPackQuantity,
                    sku: payload || isNinetyPercent ? size.sku : ''
                };
            }
            return size;
        });

        yield put(change(PO_FORM_NAME, 'sizeDetails', updatedSizeDetails));
    }
};

const setPOSizeTableRatioChildrenWorker = function*({
    payload,
    meta: { field }
}) {
    const payloadIndex = extractIndexFromAsterisk(field);
    const noOfPacks = yield select(formValueSelector(FORM_NAME), 'noOfPacks');
    const { sizeDetails, totalRatio, orderQuantity } = yield select(
        formValueSelector(PO_FORM_NAME),
        'sizeDetails',
        'totalRatio',
        'orderQuantity'
    );

    const currentPackRatio = sizeDetails[payloadIndex].packRatio;
    const updatedPackRatio = payload * noOfPacks;

    if (currentPackRatio !== updatedPackRatio) {
        const perPackQuantity = (orderQuantity / totalRatio) * payload;
        const updatedSizeDetails = sizeDetails.map((size, index) => {
            if (index === payloadIndex) {
                return {
                    ...size,
                    quantity: perPackQuantity,
                    packQuantity: perPackQuantity * noOfPacks,
                    packRatio: updatedPackRatio,
                    sku: payload ? size.sku : ''
                };
            }
            return size;
        });

        yield put(change(PO_FORM_NAME, 'sizeDetails', updatedSizeDetails));
    }
};

const setOrderBasicTrimsChildrenWorker = function*({ payload }) {
    const trimsId = payload.split('-').splice(-1)[0];
    if (trimsId) {
        try {
            yield put(setOrderTrimsLoading(true));
            const brands =
                (yield select(formValueSelector(FORM_NAME), 'brands')) || [];
            const {
                data: { data }
            } = yield call(apiRequest, {
                url: 'OrderBasicTrims/GetOrderBasicTrimDataByTrimId',
                params: { trimsId }
            });
            const { id, ...restData } = data[0];
            yield put(
                change(FORM_NAME, 'brands', [
                    ...brands,
                    { ...restData, basicTrimId: id }
                ])
            );
            yield put(change(FORM_NAME, 'orderBasicTrims', ''));
            yield put(setOrderTrimsLoading(false));
        } catch (error) {
            yield put(setOrderTrimsLoading(false));
        }
    }
};

const setOrderNoteChildrenWorker = function*({ payload: commentId }) {
    yield put(change(FORM_NAME, 'orderComment', ''));
    if (commentId) {
        const comments = yield select(presetCommentsSelector);
        const comment = comments.find(({ id }) => Number(commentId) === id)
            .orderNote;
        yield put(change(FORM_NAME, 'orderComment', comment));
    }
};

export function* routeToOrderWorker({ payload: { mode = null } = {} }) {
    const routeID =
        mode === 'new'
            ? NEW_ORDER_ROUTE_ID
            : mode === 'amend'
            ? AMEND_ORDER_ROUTE_ID
            : INCOMPLETE_ORDER_ROUTE_ID;

    yield put(destroyOrderForm()); // Not redundant -- takes care of object URLS

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    } else {
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(redirectToRoute(routeID));
}

const destroyOrderFormWorker = function*() {
    const isRedirected = yield select(isRedirectedSelector);

    if (!isRedirected) {
        // URL blob clean up
        const attachments = yield select(
            formValueSelector(FORM_NAME),
            'attachments'
        );

        if (attachments)
            attachments.forEach(
                attachment =>
                    attachment &&
                    isBlobUrl(attachment.url) &&
                    URL.revokeObjectURL(attachment.url)
            );

        yield put(destroy(FORM_NAME));
    }

    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    yield put(setRedirect(false));
};

const getIncompleteOrderDataWorker = function*({ payload: orderNo }) {
    if (orderNo) {
        yield put(setLoading(true));
        yield call(getReferenceDataWorker, orderNo);

        yield put(setLoading(false));
    }
};
const getAmendOrderDataWorker = function*({ payload: orderNo }) {
    if (orderNo) {
        yield put(setLoading(true));
        /* When setup lock activated. If user back to first step
         and choose another tbc order from dropdown then they should be able to edit sample fields. 
         But for TBC orders, tbcOrder flag is true. So, use effect won't work if we don't make it false here
         */
        yield put(change(FORM_NAME, 'tbcOrder', false));
        yield call(getReferenceDataWorker, orderNo, true);

        yield put(setLoading(false));
    }
};

const setPOExFtyDateWorker = function*({ payload: fobDate }) {
    const { retailer, factoryOrigin } = yield select(
        formValueSelector(FORM_NAME),
        'retailer',
        'factoryOrigin'
    );
    const shipMode = yield select(formValueSelector(PO_FORM_NAME), 'shipMode');
    const SEA_IDS = [1, 11];
    const RETAILER_EX_FTY_DAYS_SUBTRACT = {
        [PRIMARK_ID]: { [FACTORY_ORIGIN.TR]: 0, [FACTORY_ORIGIN.BD]: 2 },
        [NEWLOOK_ID]: {
            [FACTORY_ORIGIN.TR]: 0,
            [FACTORY_ORIGIN.BD]: SEA_IDS.includes(shipMode) ? 7 : 4
        }
    };

    if (fobDate && RETAILER_EX_FTY_DAYS_SUBTRACT[retailer]) {
        const subtractDays =
            RETAILER_EX_FTY_DAYS_SUBTRACT[retailer][factoryOrigin] || 0;
        const timeFobDate = new Date(denormalizeDate(fobDate)).getTime();
        const subtractTime = 1000 * 60 * 60 * 24 * subtractDays;
        const exftyDate = normalizeDate(timeFobDate - subtractTime);
        yield put(change(PO_FORM_NAME, 'estimateFactoryDate', exftyDate));
    }
};

const generatePOSizeSKU = function*() {
    const sizeDetails = yield select(
        formValueSelector(PO_FORM_NAME),
        'sizeDetails'
    );

    const newData = sizeDetails.map(datum => ({ ...datum }));
    const givenSKUIndex = newData.findIndex(({ sku }) => !!sku);
    const totalZeroValueUptoIndex = newData
        .slice(0, givenSKUIndex + 1)
        .filter(({ quantity }) => quantity === 0).length;
    const givenSKU = Number(newData[givenSKUIndex].sku);
    let newSku;

    newData.forEach((datum, idx) => {
        newSku = isFalsyButNotZero(newSku)
            ? givenSKU - (givenSKUIndex - totalZeroValueUptoIndex)
            : newSku;

        if (datum.quantity > 0) {
            if (idx !== givenSKUIndex) {
                datum.sku = newSku.toString();
                newSku = newSku + 1;
            } else {
                newSku = givenSKU + 1;
            }
        }
    });

    yield put(change(PO_FORM_NAME, 'sizeDetails', newData));
};

export function* resetOrderFormWorker() {
    const attachments = yield select(
        formValueSelector(FORM_NAME),
        'attachments'
    );

    if (attachments)
        attachments.forEach(
            attachment =>
                attachment &&
                isBlobUrl(attachment.url) &&
                URL.revokeObjectURL(attachment.url)
        );
    yield put(initialize(FORM_NAME, INITIAL_VALUES, false));
}

// Watchers
const orderSagas = function*() {
    yield takeLatest(RESET_SAMPLES_REFS, resetSampleRefsWorker);
    yield takeLatest(SET_SAME_STYLE_IN_ALL_PACKS, setSameStyleInAllPacksWorker);
    yield takeLatest(SET_SAMPLE_REFS_AMEND, setSampleRefsAmendWorker);
    yield takeLatest(ORDER_PUBLISH, orderPublishWorker);
    yield takeLatest(SET_CLIENT_PO_TBC_CHILDREN, setClientPOTBCChildrenWorker);
    yield takeLatest(
        FETCH_PRODUCT_DETAILS_BY_REFERENCE,
        fetchProductDetailsWorker
    );
    yield takeLatest(
        FETCH_SAMPLE_REF_CHANGE_DATA,
        fetchSampleRefChangeDataWorker
    );
    yield takeLatest(ORDER_REFERENCE_GENERATE, generateOrderReferenceWorker);
    yield takeLatest(SET_FACTORY_CHILDREN, setFactoryChildrenWorker);
    yield takeLatest(
        SET_PO_LAB_DIP_COLOR_CHILDREN,
        setPOLabDipColorChildrenWorker
    );
    yield takeLatest(SET_PO_TBC_CHILDREN, setPOTBCChildrenWorker);
    yield takeLatest(SET_PO_PACK_TBC_CHILDREN, setPOPackTBCChildrenWorker);
    yield takeLatest(SET_PO_NO_CHILDREN, setPONOChildrenWorker);
    yield takeLatest(SET_PO_SHIP_MODE_CHILDREN, setPOShipModeChildrenWorker);
    yield takeLatest(SET_PO_EX_FTY_DATE, setPOExFtyDateWorker);
    yield takeLatest(SET_PACK_TYPE_CHILDREN, setPackTypeChildrenWorker);
    yield takeLatest(SET_PO_SIZE_SET_CHILDREN, setPOSizeSetChildrenWorker);
    yield takeLatest(
        RESET_SIZE_DETAILS_ON_QUANITY_OR_RATIO_CHANGE,
        resetSizeDetailsOnQuantityAndRatioChangeWorker
    );
    yield takeLatest(WATCH_PO_REMOVED, watchPORemovedWorker);
    yield takeLatest(
        SET_ORDER_BASIC_TRIMS_CHILDREN,
        setOrderBasicTrimsChildrenWorker
    );
    yield takeLatest(SET_ORDER_NOTE_CHILDREN, setOrderNoteChildrenWorker);
    yield takeLatest(ROUTE_TO_ORDER, routeToOrderWorker);
    yield takeLatest(DESTROY_ORDER_FORM, destroyOrderFormWorker);
    yield takeLatest(GET_INCOMPLETE_ORDER_DATA, getIncompleteOrderDataWorker);
    yield takeLatest(GET_AMEND_ORDER_DATA, getAmendOrderDataWorker);
    yield takeLatest(GENERATE_PO_SIZE_SKU, generatePOSizeSKU);
    yield takeLatest(
        ORDER_REPORT_MAIL_SEND,
        orderReportUploadAndMailSendWorker
    );
    yield takeLatest(
        SET_PO_SIZE_TABLE_QUANTITY_CHILDREN,
        setPOSizeTableQuantityChildrenWorker
    );
    yield takeLatest(
        SET_PO_SIZE_TABLE_RATIO_CHILDREN,
        setPOSizeTableRatioChildrenWorker
    );
    yield takeLatest(RESET_ORDER_FORM, resetOrderFormWorker);
};

export default function*() {
    yield fork(orderSagas);
    yield fork(orderCPSagas);
}
