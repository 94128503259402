const commonFilterOperators = {
    eq: 'Equals',
    neq: 'Does not equal'
};

const rangeFilterOperator = {
    inrange: 'In range',
    notinrange: 'Not in range'
};

const textFilterOperators = {
    contains: 'Contains',
    notContains: 'Does not contain',
    ...commonFilterOperators,
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    in: 'In',
    notin: 'Not in',
    empty: 'Empty'
};

const numberFilterOperators = {
    gt: 'Greater than',
    gte: 'Greater than or equal',
    lt: 'Less than',
    lte: 'Less than or equal',
    ...commonFilterOperators,
    ...rangeFilterOperator
};

const selectFilterOperators = {
    ...commonFilterOperators
};

const dateFilterOperators = {
    after: 'After',
    before: 'Before',
    ...commonFilterOperators,
    ...rangeFilterOperator
};

const boolFilterOperators = {
    eq: 'Equals'
};

const defaultFilterOperatorValues = {
    text: 'contains',
    number: 'eq',
    date: 'eq',
    select: 'eq',
    boolean: 'eq'
};

const booleanOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
];

export {
    textFilterOperators,
    numberFilterOperators,
    selectFilterOperators,
    dateFilterOperators,
    boolFilterOperators,
    defaultFilterOperatorValues,
    booleanOptions
};
