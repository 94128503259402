import React from 'react';
import PropTypes from 'prop-types';

import {
    PortalModalContainer,
    PortalModalContent,
    PortalModalHeader
} from '@libComponents/PortalModal';

import DispatchItemsTable from './DispatchItemsTable';

const propTypes = {
    dispatchRef: PropTypes.string.isRequired,
    onModalClose: PropTypes.func,
    items: PropTypes.array
};

const defaultProps = {
    loading: false,
    onModalClose: f => f
};

const DispatchItemsModal = ({ onModalClose, dispatchRef, items }) => {
    return (
        <PortalModalContainer isOpen={true} size='lg'>
            <PortalModalHeader onClose={onModalClose}>
                {dispatchRef}
            </PortalModalHeader>
            <PortalModalContent>
                <DispatchItemsTable data={items} />
            </PortalModalContent>
        </PortalModalContainer>
    );
};

DispatchItemsModal.propTypes = propTypes;
DispatchItemsModal.defaultProps = defaultProps;

export default DispatchItemsModal;
