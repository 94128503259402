import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core';

import { deserializeFilename } from '@libs/filenames';
import {
    PortalModalContainer,
    PortalModalContent,
    PortalModalHeader
} from '@libComponents/PortalModal';

import ImageLoader from './ImageLoader';
import NavigationControls from './NavigationControls';
import ImageInfo from './ImageInfo';

const styles = () => ({
    content: {
        position: 'relative',
        padding: 0,
        '&:first-child': {
            padding: 0
        }
    }
});

const Slider = withStyles(styles)(({ images, handleClose, classes }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalImages = images.length;
    const showControls = totalImages > 1;
    const currentImagePath = images[currentIndex].size800;
    const currentImageName =
        images[currentIndex].imageVersion ||
        deserializeFilename(currentImagePath);

    const handlePrevImage = useCallback(() => {
        setCurrentIndex((currentIndex - 1 + totalImages) % totalImages);
    }, [currentIndex, totalImages]);

    const handleNextImage = useCallback(() => {
        setCurrentIndex((currentIndex + 1) % totalImages);
    }, [currentIndex, totalImages]);

    return (
        <>
            <PortalModalContainer isOpen>
                <PortalModalHeader onClose={handleClose}>
                    <ImageInfo
                        imageName={currentImageName}
                        currentIndex={currentIndex}
                        totalImages={totalImages}
                    />
                </PortalModalHeader>
                <PortalModalContent className={classes.content}>
                    <ImageLoader
                        imageSrc={currentImagePath}
                        altText={currentImageName}
                    />
                    {showControls && (
                        <NavigationControls
                            onPrev={handlePrevImage}
                            onNext={handleNextImage}
                        />
                    )}
                </PortalModalContent>
            </PortalModalContainer>
        </>
    );
});

export default Slider;
