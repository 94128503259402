import React from 'react';
import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';

import SelectVersions from './SelectVersions';

const styles = ({ spacing, typography }) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacing.unit * 2
    },
    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const Adornment = withStyles(styles)(
    ({
        classes: c,
        disabled,
        handleMailModal,
        handlePreviewModalClose,
        ...props
    }) => {
        return (
            <div className={c.container}>
                <SelectVersions {...props} />
                <div className={c.actionsContainer}>
                    <Button
                        className={c.button}
                        onClick={handleMailModal}
                        disabled={disabled}
                    >
                        Email
                    </Button>
                    <Button
                        color='error'
                        className={c.button}
                        onClick={handlePreviewModalClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        );
    }
);

export default Adornment;
