import React from 'react';

import Select from 'components/Inputs/Select';

const defaultProps = {
    label: '',
    placeholder: '',
    form: 'dataGridForm'
};

const GridSelect = props => <Select {...props} />;

GridSelect.defaultProps = defaultProps;

export default GridSelect;
