import React, { useCallback } from 'react';

import { withStyles } from '@material-ui/core';

import { GridCellContent } from 'dataGrid/components';

import RichTextModal from './RichTextModal';

const styles = ({ palette, typography }) => ({
    gridLinkCell: {
        cursor: 'pointer',
        color: palette.primary.main,
        fontWeight: typography.fontWeightSemi,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const RichText = withStyles(styles)(
    ({ classes, text, textToBeDisplayed, ...rest }) => {
        const [modalOpen, setModalOpen] = React.useState(false);

        const handleClick = useCallback(() => {
            setModalOpen(true);
        }, [setModalOpen]);

        const handleClose = useCallback(() => {
            setModalOpen(false);
        }, [setModalOpen]);

        if (!text) {
            return null;
        }

        return (
            <>
                <GridCellContent
                    content={textToBeDisplayed}
                    onClick={handleClick}
                    className={classes.gridLinkCell}
                />
                {modalOpen && (
                    <RichTextModal
                        onModalClose={handleClose}
                        text={text}
                        {...rest}
                    />
                )}
            </>
        );
    }
);

export default RichText;
