import { DOMAIN_NAME } from './common';

export const ROUTE_TO_SEAL_SAMPLE = `${DOMAIN_NAME}@route-to-seal-sample`;
export const DESTROY_SEAL_SAMPLE_FORM = `${DOMAIN_NAME}@destroy-seal-sample-form`;
export const SEAL_SAMPLE_PUBLISH = `${DOMAIN_NAME}@seal-sample-publish`;
export const SET_SEAL_SAMPLE_COLOR_CHILDREN = `${DOMAIN_NAME}@set-seal-sample-color-children`;
export const SET_SEAL_SAMPLE_COLOR_CHILDREN_LOADING = `${DOMAIN_NAME}@set-seal-sample-color-children-loading`;
export const SEAL_SAMPLE_REF_GENERATE = `${DOMAIN_NAME}@seal-sample-ref-generate`;
export const SET_SEAL_SAMPLE_REF_GENERATE_LOADING = `${DOMAIN_NAME}@set-seal-sample-ref-generate-loading`;

export const GET_SEAL_SAMPLE_DATA = `${DOMAIN_NAME}@get-seal-sample-data`;

export const SET_SEAL_SAMPLE_MAIL_RECEIVER = `${DOMAIN_NAME}@set-seal-sample-mail-receiver`;
export const SET_SEAL_SAMPLE_MAIL_BODY = `${DOMAIN_NAME}@set-seal-sample-mail-body`;

export const GET_COPY_SEAL_SAMPLE_DATA = `${DOMAIN_NAME}@get-copy-seal-sample-data`;

export const REDIRECT_TO_SS_AND_FETCH_REF_DATA = `${DOMAIN_NAME}@redirect-to-ss-and-fetch-ref-data`;
export const SEAL_SAMPLE_EXPORT_AS_EXCEL = `${DOMAIN_NAME}@seal-sample-export-as-excel`;
export const SET_SEAL_SAMPLE_EXPORT_AS_EXCEL_LOADING = `${DOMAIN_NAME}@set-seal-sample-export-as-excel-loading`;
