import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';

import { withStyles, Grid, Divider } from '@material-ui/core';

// Lib
import ContentContainer from '@libComponents/ContentContainer';
import { Checkbox, GridItem, Input } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { setReloadAction } from 'utilities/redux.actions';

import {
    fetchProductDetailsByReference,
    fetchSampleRefChangeData,
    orderReferenceGenerate,
    setFactoryChildren,
    setClientPOTBCChildren
} from 'orders/redux.actions';
import {
    orderNoLoadingSelector,
    clientPOTBCLoadingSelector,
    factoryRefLoadingSelector,
    isPrimarkSelector,
    isAsosSelector,
    isNewlookSelector
} from 'orders/redux.selectors';

import { FORM_NAME, LABELS } from '../../common';
import { useOrderState } from '../../useOrder';
import asyncValidate from './asyncValidate';
import ProductsContainer from './ProductsContainer';
import HeaderAdornment from '../HeaderAdornment';
import {
    Retailer,
    SelectDepartments,
    SelectSendToBuyer,
    SelectSendToEcho,
    SelectSeasons
} from 'sampling/components';
import SelectRetailerFactories from './SelectRetailerFactories';
import SampleSendDate from './SampleSendDate';
import SelectProductionSampleType from './SelectProductionSampleType';
import PurchaseOrdersContainer from './PurchaseOrdersContainer';

const styles = ({ spacing, breakpoints }) => ({
    contentContainer: {
        paddingBottom: spacing.unit * 6
    },
    wrapper: {
        padding: `${spacing.unit * 4}px 0px`
    },
    container: {
        width: '64%',
        margin: 'auto',
        [breakpoints.down(1920)]: {
            width: '80%'
        },
        [breakpoints.down(1280)]: {
            width: '90%'
        }
    },
    fieldContainer: {
        paddingBottom: 0
    },
    tbcWrapper: {
        position: 'relative'
    },
    tbc: {
        position: 'absolute',
        right: 0,
        top: -2
    },
    checkboxLabel: { fontSize: spacing.unit * 1.5 },
    checkboxIcon: { width: spacing.unit * 2.5, height: spacing.unit * 2.5 },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 4,
        marginBottom: spacing.unit * 2
    }
});

const mapState = state => {
    const formValues = getFormValues(FORM_NAME)(state) || {};
    return {
        formValues,
        version: `V${formValues.versionNo}`,
        orderNoLoading: orderNoLoadingSelector(state),
        factoryRefLoading: factoryRefLoadingSelector(state),
        isPrimark: isPrimarkSelector(state),
        isAsos: isAsosSelector(state),
        isNewlook: isNewlookSelector(state),
        clientPOTBCLoading: clientPOTBCLoadingSelector(state)
    };
};

const mapDispatch = {
    fetchProductDetails: fetchProductDetailsByReference,
    orderNoGenerate: orderReferenceGenerate,
    fetchSampleRefChangeData
};

const mapInputs = {
    clientPOTBC: ({ payload }) => setClientPOTBCChildren(payload),
    factory: ({ payload }) => setFactoryChildren(payload),
    purchaseOrders: ({ payload }) => {
        const shouldPrompt = payload.some(({ id }) => !id);
        return setReloadAction({
            field: 'purchaseOrders',
            shouldPrompt,
            action: f => f
        });
    }
};

const Details = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['clientPONo']
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        formValues: {
            products = [],
            echoOrderNo,
            shouldSampleRefChangeApiCall
        },
        orderNoLoading,
        factoryRefLoading,
        fetchProductDetails,
        fetchSampleRefChangeData,
        orderNoGenerate,
        isPrimark,
        isAsos,
        isNewlook,
        clientPOTBCLoading,
        version
    }) => {
        const { mode } = useOrderState();

        const shouldSampleDetailsApiCall = useMemo(() => {
            if (mode === 'new' && products.length === 0) return true;
            return false;
        }, [mode, products]);

        const shouldGenerateEchoOrderNo = useMemo(() => {
            if (mode === 'new' && !echoOrderNo) return true;
            return false;
        }, [mode, echoOrderNo]);

        useEffect(() => {
            shouldSampleDetailsApiCall && fetchProductDetails();
        }, [shouldSampleDetailsApiCall]);

        useEffect(() => {
            shouldGenerateEchoOrderNo && orderNoGenerate();
        }, [shouldGenerateEchoOrderNo]);

        useEffect(() => {
            mode !== 'new' &&
                shouldSampleRefChangeApiCall &&
                fetchSampleRefChangeData();
        }, [shouldSampleRefChangeApiCall, mode]);

        return (
            <ContentContainer
                title={LABELS.details}
                styles={{ container: c.contentContainer }}
                AdornmentComponent={<HeaderAdornment />}
            >
                <div className={c.wrapper}>
                    <Grid container className={c.container}>
                        <GridItem md={12}>
                            <Grid container>
                                <GridItem md={4}>
                                    <Retailer form={FORM_NAME} />
                                </GridItem>
                                <GridItem md={4}>
                                    <SelectDepartments
                                        required
                                        disabled={mode !== 'new'}
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Input
                                        name='echoOrderNo'
                                        label='Echo Order No'
                                        disabled
                                        loading={orderNoLoading}
                                        styles={{ container: c.fieldContainer }}
                                        required
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Input
                                        name='versionNo'
                                        label='Version No'
                                        staticValue={version}
                                        disabled
                                        loading={orderNoLoading}
                                        styles={{ container: c.fieldContainer }}
                                        required
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Input
                                        name='orderAmendedDate'
                                        label='Date Order Amended'
                                        disabled
                                        styles={{ container: c.fieldContainer }}
                                        loading={orderNoLoading}
                                        required
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Input
                                        name='orderPlacedDate'
                                        label='Date Order Placed'
                                        disabled
                                        styles={{ container: c.fieldContainer }}
                                        loading={orderNoLoading}
                                        required
                                    />
                                </GridItem>
                                {isNewlook && (
                                    <GridItem md={4}>
                                        <SampleSendDate />
                                    </GridItem>
                                )}
                                <GridItem md={4}>
                                    <Input
                                        name='licensor'
                                        label='Licensor'
                                        placeholder='Licensor Name'
                                        disabled
                                        styles={{ container: c.fieldContainer }}
                                        loading={orderNoLoading}
                                    />
                                </GridItem>
                            </Grid>
                        </GridItem>
                        <Divider className={c.divider} />
                    </Grid>
                    <Grid container className={c.container}>
                        <GridItem md={12}>
                            <Grid container>
                                <GridItem md={4}>
                                    <Input
                                        name='styleNo'
                                        label='Style No / ORIN'
                                        placeholder='Enter Style No'
                                        required={!isAsos}
                                    />
                                </GridItem>
                                <GridItem md={8}>
                                    <Input
                                        name='productDescription'
                                        label='Product Description'
                                        placeholder='Enter Product Description'
                                    />
                                </GridItem>
                                {isPrimark && (
                                    <GridItem md={4} className={c.tbcWrapper}>
                                        <Input
                                            name='clientPONo'
                                            label='Client PO NO'
                                            placeholder='Enter Client PO NO'
                                            loading={clientPOTBCLoading}
                                            required
                                        />
                                        <div className={c.tbc}>
                                            <Checkbox
                                                name='clientPOTBC'
                                                label='TBC'
                                                styles={{
                                                    label: c.checkboxLabel,
                                                    icon: c.checkboxIcon
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                )}
                                <GridItem md={4}>
                                    <SelectRetailerFactories required />
                                </GridItem>
                                <GridItem md={4}>
                                    <Input
                                        name='factoryRef'
                                        label='Factory Ref'
                                        disabled
                                        styles={{ container: c.fieldContainer }}
                                        required
                                        loading={factoryRefLoading}
                                    />
                                </GridItem>
                                {!isAsos && (
                                    <GridItem md={4}>
                                        <Input
                                            name='supplierNo'
                                            label='Supplier No'
                                            loading={factoryRefLoading}
                                            required
                                        />
                                    </GridItem>
                                )}
                                <GridItem md={4}>
                                    <SelectSeasons required />
                                </GridItem>
                                <GridItem md={4}>
                                    <SelectProductionSampleType />
                                </GridItem>
                                <GridItem md={4}>
                                    <SelectSendToEcho
                                        label={'No. Send To Echo'}
                                        formname={FORM_NAME}
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <SelectSendToBuyer
                                        label={'No. Send To Buyer'}
                                        formname={FORM_NAME}
                                    />
                                </GridItem>
                                {!isAsos && (
                                    <GridItem md={4}>
                                        <Input
                                            name='systemFactoryNo'
                                            label='System Factory No'
                                        />
                                    </GridItem>
                                )}
                                {isPrimark && (
                                    <>
                                        <GridItem md={4}>
                                            <Input
                                                name='kimballNo'
                                                label='Kimball No'
                                                placeholder='Enter Kimball No'
                                                required
                                            />
                                        </GridItem>
                                        <GridItem md={4}>
                                            <Input
                                                name='kimballColor'
                                                label='Kimball Color'
                                                placeholder='Enter Kimball Color'
                                            />
                                        </GridItem>
                                    </>
                                )}
                                {isAsos && (
                                    <GridItem md={4}>
                                        <Input
                                            name='commodity'
                                            label='Commodity'
                                            placeholder='Enter Commodity'
                                        />
                                    </GridItem>
                                )}
                                {isNewlook && (
                                    <GridItem md={4}>
                                        <Input name='nlKind' label='NL Kind' />
                                    </GridItem>
                                )}
                                <GridItem md={4}>
                                    <Checkbox
                                        name='blankOrder'
                                        label='Blank Order'
                                    />
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </div>
                <ProductsContainer required />
                <PurchaseOrdersContainer required mode={mode} />
            </ContentContainer>
        );
    }
);

export default Details;
