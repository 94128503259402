import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Libs
import { entitySelector, valueSelector } from '@libs/datasets';
import { roundNumber } from '@libs/number';
import { deserializeFilename } from '@libs/filenames';
import {
    denormalizeDate,
    normalizeDate,
    deserializeDate
} from '@baseComponents/Date/normalizeDate';
import isFieldAmended from '@libs/isFieldAmended';
import combinedGroupValue from '@baseComponents/InputGroupSelect/combinedGroupValue';
import { MAIN_BODY_FABRIC_ID } from 'sampling/common';

// Local
import {
    DOMAIN_NAME,
    FORM_NAME,
    PO_FORM_NAME,
    DIP_INITIAL_VALUES
} from './Order/common';
import {
    NINETY_PERCENT_ID,
    PRIMARK_ID,
    ASOS_ID,
    NEWLOOK_ID,
    SEASALT_ID,
    RAB_ID
} from 'orders/common';
import {
    NP_COLORS,
    ORDER_NOTES,
    ORDER_USER_RETAILER_WISE_FACTORIES,
    PRODUCTION_SAMPLE_TYPE,
    PO_SHIP_MODE,
    PO_SHIP_DESTINATION,
    PO_TYPE,
    PACKING_PACK_TYPE,
    PACKING_BOX_TYPE,
    AMEND_ORDERS,
    ORDER_SUSTAINABILITY,
    COLUMN_GROUP
} from 'orders/redux.datasets';
import {
    RETAILERS,
    DEPARTMENTS,
    GET_SAMPLE_SIZES,
    COMPOSITIONS,
    FABRIC_TYPES,
    FINISHED_GSMS,
    GARMENTS_TYPE,
    SEASONS
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import { DOMAIN_NAME as GRID_VIEW_DOMAIN_NAME } from 'gridView/common';

const ordersSelector = state => state[DOMAIN_NAME];

const orderFormSelector = formValueSelector(FORM_NAME);

const POFormSelector = formValueSelector(PO_FORM_NAME);

const getOrderFormErrorsSelector = state => state.form[FORM_NAME].syncErrors;

const getPOFormErrorsSelector = state => {
    const POForm = state.form[PO_FORM_NAME];
    return (POForm && POForm.syncErrors) || {};
};

const isNinetyPercentSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === NINETY_PERCENT_ID
);

const isPrimarkSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === PRIMARK_ID
);

const isAsosSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === ASOS_ID
);

const isNewlookSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === NEWLOOK_ID
);

const isSeasaltSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === SEASALT_ID
);

const isRabSelector = createSelector(
    state => orderFormSelector(state, 'retailer'),
    retailer => retailer === RAB_ID
);

const clientPOTBCLoadingSelector = createSelector(
    ordersSelector,
    ({ clientPOTBCLoading }) => clientPOTBCLoading
);

const productsLoadingSelector = createSelector(
    ordersSelector,
    ({ productsLoading }) => productsLoading
);

const sampleRefChangeDataLoadingSelector = createSelector(
    ordersSelector,
    ({ sampleRefChangeDataLoading }) => sampleRefChangeDataLoading
);

const orderNoLoadingSelector = createSelector(
    ordersSelector,
    ({ orderNoLoading }) => orderNoLoading
);

const factoryRefLoadingSelector = createSelector(
    ordersSelector,
    ({ factoryRefLoading }) => factoryRefLoading
);

const getFabricProperties = fabrics => {
    const defaultFabricProperties = {
        compositionId: null,
        fabricTypeId: null,
        gsmId: null
    };

    const fabric = fabrics.find(
        ({ fabricForId }) => MAIN_BODY_FABRIC_ID === fabricForId
    );
    return fabric || defaultFabricProperties;
};

const labDipsSelector = createSelector(
    products => products || [],
    (_, retailer) => retailer,
    (_, __, factoryOrigin) => factoryOrigin,
    (products, retailer, factoryOrigin) => {
        const initialValues = DIP_INITIAL_VALUES(retailer, factoryOrigin);
        if (ASOS_ID === Number(retailer)) {
            return products.map(({ sampleRef, fabrics }) => {
                const {
                    compositionId: composition,
                    fabricTypeId: fabricType,
                    gsmId: gsm
                } = getFabricProperties(fabrics);
                return {
                    ...initialValues,
                    sampleRef,
                    composition,
                    fabricType,
                    gsm
                };
            });
        }
        return products.map(({ sampleRef }) => ({
            ...initialValues,
            sampleRef
        }));
    }
);

const distinctProductsSelector = createSelector(
    products => products || [],
    products => [
        ...new Map(
            products.map(product => [product['sampleRef'], product])
        ).values()
    ]
);

const sampleSendDateSelector = createSelector(
    state => orderFormSelector(state, 'purchaseOrders') || [],
    poDetails => {
        const convertToTime = date => {
            const [day, month, year] = date.split('/');
            return new Date(year, month - 1, day).getTime();
        };
        const INITIAL_VALUE = '01/01/2200';
        const smallerExFtyDate = poDetails.reduce(
            (previous, { estimateFactoryDate }) => {
                return convertToTime(previous) >
                    convertToTime(estimateFactoryDate)
                    ? estimateFactoryDate
                    : previous;
            },
            INITIAL_VALUE
        );
        if (INITIAL_VALUE === smallerExFtyDate) return '';
        const daysBufferNegative = 14;
        const timeFrom = new Date(denormalizeDate(smallerExFtyDate)).getTime();
        const timeBuffer = 1000 * 60 * 60 * 24 * daysBufferNegative;
        const calculatedDate = timeFrom - timeBuffer;
        return normalizeDate(calculatedDate);
    }
);

const colorsDataSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, NP_COLORS),
    (state, retailerId) => retailerId === NINETY_PERCENT_ID,
    (state, retailerId, sampleRef) => sampleRef,
    (state, retailerId, sampleRef, batchConfirmation) => batchConfirmation,
    (colors, isNinetyPercent, sampleRef, batchConfirmation) => {
        let data = colors;
        if (isNinetyPercent) {
            data = colors.filter(
                ({ sampleRef: ref, batchRef }) =>
                    ref === sampleRef && batchRef === batchConfirmation
            );
        }
        return data.reduce((acm, { id, colorName }) => {
            return [...acm, { label: colorName, value: id }];
        }, []);
    }
)(
    (state, retailerId, sampleRef, batchConfirmation) =>
        `colors-${retailerId}-${sampleRef}-${batchConfirmation}`
);

const batchRefsDataSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, NP_COLORS),
    (state, sampleRef) => sampleRef,
    (colors, sampleRef) =>
        colors
            .filter(({ sampleRef: ref }) => ref === sampleRef)
            .map(({ batchRef }) => ({ label: batchRef, value: batchRef }))
)((state, sampleRef) => `batch-refs-${sampleRef}`);

const labDipColorLoadingSelector = createSelector(
    ordersSelector,
    (_, loadingKey) => loadingKey,
    (order, loadingKey) => !!order[loadingKey]
);

const poTBCLoadingSelector = createSelector(
    ordersSelector,
    ({ poTBCLoading }) => poTBCLoading
);

const poPackTBCLoadingSelector = createSelector(
    ordersSelector,
    ({ poPackTBCLoading }) => poPackTBCLoading
);

const poSizeSetLoadingSelector = createSelector(
    ordersSelector,
    ({ poSizeSetLoading }) => poSizeSetLoading
);

const isPackTypeRatioSelector = createSelector(
    state => POFormSelector(state, 'packType'),
    type => type === 'Ratio'
);

const PONoCheckLoadingSelector = createSelector(
    ordersSelector,
    ({ poOrPackCheckLoading }) => poOrPackCheckLoading
);

const paymentAndSettlementDeafaultValueLoadingSelector = createSelector(
    ordersSelector,
    ({ paymentAndSettlementDeafaultValueLoading }) =>
        paymentAndSettlementDeafaultValueLoading
);

const orderTrimsLoadingSelector = createSelector(
    ordersSelector,
    ({ orderTrimsLoading }) => orderTrimsLoading
);

const costBasedOnSelector = createSelector(
    state => entitySelector(state, COMMON_DOMAIN_NAME, DEVELOPMENT_TYPES),
    state => orderFormSelector(state, 'type'),
    (devTypes, type) => {
        const moduleName = type === 'P' ? 'Print' : 'Non Print';
        return devTypes
            .filter(
                ({ developmentModuleType }) =>
                    moduleName === developmentModuleType
            )
            .map(({ id, developmentName }) => ({
                label: developmentName,
                value: id
            }));
    }
);

const presetCommentsSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, ORDER_NOTES),
    comments => comments
);

const discountSelector = createSelector(
    dips => dips,
    (_, discount) => discount,
    (dips, discount) => {
        if (discount) {
            const {
                echoTotal,
                fobTotal,
                echoCurrency,
                fobCurrency
            } = dips.reduce(
                (
                    { echoTotal, fobTotal },
                    {
                        echoPrice: {
                            inputValue: echoValue,
                            selectedValue: echoCurrency
                        } = {},
                        fobPrice: {
                            inputValue: fobValue,
                            selectedValue: fobCurrency
                        } = {}
                    }
                ) => ({
                    echoTotal: echoTotal + Number(echoValue),
                    fobTotal: fobTotal + Number(fobValue),
                    echoCurrency,
                    fobCurrency
                }),
                { echoTotal: 0, fobTotal: 0 }
            );

            return {
                echoDiscount: `${echoCurrency}${roundNumber(
                    echoTotal - Number(discount)
                )}`,
                fobDiscount: `${fobCurrency}${roundNumber(
                    fobTotal - Number(discount)
                )}`
            };
        }
        return {};
    }
);

const reportPODetailsSelector = createSelector(
    state => orderFormSelector(state, 'purchaseOrders', 'noOfPacks'),
    state => isAsosSelector(state),
    state => isNinetyPercentSelector(state),
    state => isPrimarkSelector(state),
    state => isRabSelector(state),
    state => state,
    (state, shouldAmendCheck) => shouldAmendCheck,
    (
        { purchaseOrders = [], noOfPacks },
        isAsos,
        isNinetyPercent,
        isPrimark,
        isRab,
        state,
        shouldAmendCheck
    ) => {
        const isPrimarkOrRab = isPrimark || isRab;
        const { poDetails: prevPODetails = [] } =
            orderFormSelector(state, 'lastFullPublishedPayload') || {};
        /**
         * @variable sizeDefaultValues
         * In API payload default integer value is null but in form default value is 0.
         * For this reason when we compare value in size details it finds difference and make backgroud yellow.
         * To avoid such scenario we set default values here. we could manage it from compose payload method but
         * it might affect other payload
         */
        const sizeDefaultValues = {
            quantity: 0,
            packQuantity: 0,
            ratio: 0,
            packRatio: 0,
            sku: '',
            barCode: ''
        };

        const {
            sizeWiseTotal = {},
            ...preparedPOTable
        } = purchaseOrders.reduce(
            (
                acm,
                {
                    poNo,
                    poPackId,
                    orderType,
                    shipMode,
                    shipDestination,
                    packType,
                    estimateFactoryDate,
                    freeOnBoardDate,
                    retailPrice,
                    orderQuantity,
                    totalRatio,
                    orderStyle,
                    dips,
                    sizeDetails,
                    discount = ''
                },
                idx
            ) => {
                let prevPOContent = prevPODetails[idx] || {};
                const isNewPO = Object.values(prevPOContent).length === 0;

                const amendmentChecker = isFieldAmended(
                    shouldAmendCheck,
                    isNewPO
                );

                const preparedDips = dips.reduce(
                    (
                        acm,
                        {
                            sampleRef,
                            batchConfirmation,
                            color,
                            colorCode = '',
                            sustainability = 0,
                            colorRecipe = '',
                            fobPrice,
                            composition,
                            fabricType,
                            gsm
                        },
                        idx
                    ) => {
                        const prevLabDetailsContent = isNewPO
                            ? {}
                            : prevPOContent.labDetails[idx];
                        const compositionValue = valueSelector(
                            state,
                            SAMPLING_DOMAIN_NAME,
                            COMPOSITIONS,
                            composition
                        );
                        const fabricTypeValue = valueSelector(
                            state,
                            SAMPLING_DOMAIN_NAME,
                            FABRIC_TYPES,
                            fabricType
                        );
                        const gsmValue = valueSelector(
                            state,
                            SAMPLING_DOMAIN_NAME,
                            FINISHED_GSMS,
                            gsm
                        );
                        const isFabricationAmend =
                            amendmentChecker(
                                prevLabDetailsContent.compositionId,
                                composition
                            ) ||
                            amendmentChecker(
                                prevLabDetailsContent.fabricTypeId,
                                fabricType
                            ) ||
                            amendmentChecker(prevLabDetailsContent.gsmId, gsm);
                        const fabrication = compositionValue
                            ? `${compositionValue} / ${fabricTypeValue} / ${gsmValue}`
                            : null;
                        const fabricationValue = {
                            value: fabrication,
                            isAmend: isFabricationAmend
                        };
                        let colorValue = '';
                        if (isNinetyPercent) {
                            colorValue = {
                                value: valueSelector(
                                    state,
                                    DOMAIN_NAME,
                                    NP_COLORS,
                                    color
                                ),
                                isAmend: amendmentChecker(
                                    prevLabDetailsContent.colorName,
                                    color
                                )
                            };
                        } else {
                            const colorArray = color.split('-');
                            const colorId = Number(
                                colorArray[colorArray.length - 1]
                            );
                            colorArray.pop();

                            colorValue = {
                                value: colorArray.join(''),
                                isAmend: amendmentChecker(
                                    prevLabDetailsContent.colorName,
                                    colorId
                                )
                            };
                        }
                        const sustainabilityValue = {
                            value: valueSelector(
                                state,
                                DOMAIN_NAME,
                                ORDER_SUSTAINABILITY,
                                sustainability
                            ),
                            isAmend: amendmentChecker(
                                prevLabDetailsContent.orderSustainabilityId,
                                sustainability
                            )
                        };
                        const fobPriceValue = {
                            value: combinedGroupValue(fobPrice),
                            isAmend: amendmentChecker(
                                combinedGroupValue(
                                    prevLabDetailsContent.fobPrice
                                ).trim(),
                                combinedGroupValue(fobPrice).trim()
                            )
                        };
                        const sampleRefValue = {
                            value: sampleRef,
                            isAmend: amendmentChecker(
                                sampleRef,
                                prevLabDetailsContent.sampleRef
                            )
                        };
                        const colorCodeValue = {
                            value: colorCode,
                            isAmend: amendmentChecker(
                                colorCode,
                                prevLabDetailsContent.colorCode
                            )
                        };
                        const batchConfirmationValue = {
                            value: batchConfirmation,
                            isAmend: amendmentChecker(
                                batchConfirmation,
                                prevLabDetailsContent.batchRef
                            )
                        };
                        const labDipValue = {
                            value: colorRecipe,
                            isAmend: amendmentChecker(
                                colorRecipe,
                                prevLabDetailsContent.colorNote
                            )
                        };
                        return {
                            'Sam Ref': acm.hasOwnProperty('Sam Ref')
                                ? [...acm['Sam Ref'], sampleRefValue]
                                : [sampleRefValue],
                            ...(isNinetyPercent
                                ? {
                                      'Batch Ref': acm['Batch Ref']
                                          ? [
                                                ...acm['Batch Ref'],
                                                batchConfirmationValue
                                            ]
                                          : [batchConfirmationValue]
                                  }
                                : {}),
                            Colour: acm.hasOwnProperty('Colour')
                                ? [...acm['Colour'], colorValue]
                                : [colorValue],
                            'Colour Code': acm.hasOwnProperty('Colour Code')
                                ? [...acm['Colour Code'], colorCodeValue]
                                : [colorCodeValue],
                            'Lab Dip': acm.hasOwnProperty('Lab Dip')
                                ? [...acm['Lab Dip'], labDipValue]
                                : [labDipValue],
                            ...(isAsos
                                ? {
                                      Sustainability: acm.hasOwnProperty(
                                          'Sustainability'
                                      )
                                          ? [
                                                ...acm['Sustainability'],
                                                sustainabilityValue
                                            ]
                                          : [sustainabilityValue]
                                  }
                                : {}),
                            ...(isAsos
                                ? {
                                      Fabrication: acm['Fabrication']
                                          ? [
                                                ...acm['Fabrication'],
                                                fabricationValue
                                            ]
                                          : [fabricationValue]
                                  }
                                : {}),
                            'FOB Price in PC': acm.hasOwnProperty(
                                'FOB Price in PC'
                            )
                                ? [...acm['FOB Price in PC'], fobPriceValue]
                                : [fobPriceValue]
                        };
                    },
                    {}
                );
                const modeValue = {
                    value: isNinetyPercent
                        ? shipMode
                        : valueSelector(
                              state,
                              DOMAIN_NAME,
                              PO_SHIP_MODE,
                              shipMode
                          ),
                    isAmend: amendmentChecker(
                        isNinetyPercent
                            ? prevPOContent.poModeName
                            : Number(prevPOContent.poModeName),
                        shipMode
                    )
                };
                const destinationValue = {
                    value: isNinetyPercent
                        ? shipDestination
                        : valueSelector(
                              state,
                              DOMAIN_NAME,
                              PO_SHIP_DESTINATION,
                              shipDestination
                          ),
                    isAmend: amendmentChecker(
                        isNinetyPercent
                            ? prevPOContent.poDestinationName
                            : Number(prevPOContent.poDestinationName),
                        shipDestination
                    )
                };
                const orderTypeValue = {
                    value: isNinetyPercent
                        ? orderType
                        : valueSelector(state, DOMAIN_NAME, PO_TYPE, orderType),
                    isAmend: amendmentChecker(
                        isNinetyPercent
                            ? prevPOContent.poTypeName
                            : Number(prevPOContent.poTypeName),
                        orderType
                    )
                };

                const retailCombinedValue = combinedGroupValue(retailPrice);
                const priceOfRetail = {
                    value: isAsos
                        ? null
                        : retailCombinedValue
                        ? retailCombinedValue
                        : 'N/A',
                    isAmend: amendmentChecker(
                        combinedGroupValue(prevPOContent.retailPrice),
                        retailCombinedValue
                    )
                };

                const types = getPOSizeTableHeadersSelector(sizeDetails)
                    .map(({ name, header }) => {
                        if (name === 'sizeName') return null;
                        if (name === 'quantity') {
                            return {
                                value:
                                    noOfPacks === 1 ? 'Qty in Pcs' : 'Pack Qty',
                                isAmend: shouldAmendCheck && isNewPO
                            };
                        }

                        if (name === 'packQuantity') {
                            return noOfPacks === 1
                                ? null
                                : {
                                      value: 'Qty in Pcs',
                                      isAmend: shouldAmendCheck && isNewPO
                                  };
                        }

                        if (name === 'packRatio') {
                            return noOfPacks === 1
                                ? null
                                : {
                                      value: header,
                                      isAmend: shouldAmendCheck && isNewPO
                                  };
                        }

                        return {
                            value: header,
                            isAmend: shouldAmendCheck && isNewPO
                        };
                    })
                    .filter(value => value);

                /**
                 * @variable sizesValueChangedObj
                 * if any of the key's value changes in sizes array technically poWiseTotal will also change. so we don't
                 * need to create functionality for that again. We can manage it using this obj
                 */

                const sizesValueChangedObj = {};

                const sizes = sizeDetails.reduce(
                    (acm, { sizeName, locked, ...values }, idx) => {
                        let sizeValues = values;

                        return {
                            ...acm,
                            [sizeName]: Object.entries(sizeValues)
                                .map(([key, value]) => {
                                    const { sizeDetails = [] } = prevPOContent;
                                    const size = sizeDetails[idx] || {};
                                    const isSizeValueAmend = amendmentChecker(
                                        size[key] || sizeDefaultValues[key],
                                        value
                                    );
                                    sizesValueChangedObj[key] =
                                        sizesValueChangedObj[key] ||
                                        isSizeValueAmend;
                                    switch (key) {
                                        case 'quantity':
                                            return {
                                                value,
                                                serial: 1,
                                                isAmend: isSizeValueAmend
                                            };
                                        case 'packQuantity':
                                            return noOfPacks === 1
                                                ? null
                                                : {
                                                      value,
                                                      serial: 2,
                                                      isAmend: isSizeValueAmend
                                                  };
                                        case 'ratio':
                                            return {
                                                value,
                                                serial: 3,
                                                isAmend: isSizeValueAmend
                                            };
                                        case 'packRatio':
                                            return noOfPacks === 1
                                                ? null
                                                : {
                                                      value,
                                                      serial: 4,
                                                      isAmend: isSizeValueAmend
                                                  };
                                        case 'sku':
                                            return {
                                                value,
                                                serial: 5,
                                                isAmend: isSizeValueAmend
                                            };
                                        case 'barCode':
                                            return {
                                                value,
                                                serial: 6,
                                                isAmend: isSizeValueAmend
                                            };
                                        default:
                                            return null;
                                    }
                                })
                                .filter(value => value)
                                .sort((a, b) => a.serial - b.serial)
                                .map(({ serial, ...rest }) => rest)
                        };
                    },
                    {}
                );

                const poWiseTotal = Object.entries(
                    getPOSizeDataSummarySelector(sizeDetails)
                )
                    .map(([key, value]) => {
                        if (key === 'sizeName') return null;
                        if (noOfPacks === 1 && key === 'packQuantity')
                            return null;
                        if (noOfPacks === 1 && key === 'packRatio') return null;
                        return {
                            value,
                            isAmend: sizesValueChangedObj[key]
                        };
                    })
                    .filter(value => value);

                const sizeWiseTotal = sizeDetails.reduce(
                    (
                        { grandTotal = 0, ...totalAcm },
                        { sizeName, packQuantity }
                    ) => {
                        const prevValue = acm.hasOwnProperty('sizeWiseTotal')
                            ? acm.sizeWiseTotal[sizeName]
                            : 0;
                        const newValue = prevValue + packQuantity;
                        return {
                            ...totalAcm,
                            [sizeName]: newValue,
                            grandTotal: grandTotal + newValue
                        };
                    },
                    {}
                );

                const packQuantity = totalRatio
                    ? orderQuantity / totalRatio
                    : 0;
                const prevPackQuantity = totalRatio
                    ? prevPOContent.quantity / totalRatio
                    : 0;

                return {
                    ...(idx === 0
                        ? {
                              keys: [
                                  'PO No',
                                  ...(isPrimarkOrRab
                                      ? [isPrimark ? 'Pack ID' : 'Dest. ID']
                                      : []),
                                  ...(isAsos ? ['Option ID'] : []),
                                  ...Object.keys(preparedDips),
                                  ...(isAsos
                                      ? ['FOB in Pack(after disc)']
                                      : []),
                                  'Mode',
                                  'Dest.',
                                  'PO Type',
                                  'Ex. Fty',
                                  'Delivery',
                                  ...(retailCombinedValue ? ['Rt Pr.'] : []),
                                  'Qty.',
                                  'Total Pack Qty.',
                                  'Type',
                                  ...Object.keys(sizes),
                                  'Total'
                              ]
                          }
                        : acm),
                    values: [
                        ...acm.values,
                        [
                            {
                                value: poNo,
                                isAmend: amendmentChecker(
                                    prevPOContent.pono,
                                    poNo
                                )
                            },
                            ...(isPrimarkOrRab
                                ? [
                                      {
                                          value: poPackId,
                                          isAmend: amendmentChecker(
                                              prevPOContent.packId,
                                              poPackId
                                          )
                                      }
                                  ]
                                : []),
                            ...(isAsos
                                ? [
                                      {
                                          value: orderStyle,
                                          isAmend: amendmentChecker(
                                              prevPOContent.styleNo,
                                              orderStyle
                                          )
                                      }
                                  ]
                                : []),
                            ...Object.values(preparedDips),
                            ...(isAsos
                                ? [
                                      {
                                          value: discountSelector(
                                              dips,
                                              discount
                                          ).fobDiscount,
                                          isAmend: amendmentChecker(
                                              prevPOContent.discount,
                                              discount
                                          )
                                      }
                                  ]
                                : []),
                            modeValue,
                            destinationValue,
                            orderTypeValue,
                            {
                                value: estimateFactoryDate,
                                isAmend: amendmentChecker(
                                    normalizeDate(prevPOContent.exFtyDate),
                                    estimateFactoryDate
                                )
                            },
                            {
                                value: freeOnBoardDate,
                                isAmend: amendmentChecker(
                                    normalizeDate(prevPOContent.fobDate),
                                    freeOnBoardDate
                                )
                            },
                            ...(retailCombinedValue ? [priceOfRetail] : []),
                            {
                                value: orderQuantity,
                                isAmend: amendmentChecker(
                                    prevPOContent.quantity,
                                    orderQuantity
                                )
                            },
                            {
                                value: packQuantity,
                                isAmend: amendmentChecker(
                                    prevPackQuantity,
                                    packQuantity
                                )
                            },
                            types,
                            ...Object.values(sizes),
                            poWiseTotal
                        ]
                    ],
                    sizeWiseTotal
                };
            },
            { keys: [], values: [] }
        );

        return {
            poLength: purchaseOrders.length,
            poTable: preparedPOTable,
            grandTotal: {
                keys: Object.keys(sizeWiseTotal),
                values: Object.values(sizeWiseTotal)
            }
        };
    }
);

const factoryValueSelector = createCachedSelector(
    state =>
        entitySelector(state, DOMAIN_NAME, ORDER_USER_RETAILER_WISE_FACTORIES),
    (_, value) => value,
    (factories, value) => {
        const { factory } = factories.find(({ id }) => id === value) || {};
        return factory;
    }
)((state, value) => `factory-${value}`);

const reportPropsSelector = createSelector(
    state =>
        orderFormSelector(
            state,
            'type',
            'isTBC',
            'retailer',
            'noOfPacks',
            'department',
            'echoOrderNo',
            'versionNo',
            'orderAmendedDate',
            'orderPlacedDate',
            'sampleSendDate',
            'licensor',
            'styleNo',
            'productDescription',
            'factory',
            'factoryRef',
            'supplierNo',
            'season',
            'productionSampleType',
            'sendToEcho',
            'sendToBuyer',
            'systemFactoryNo',
            'kimballNo',
            'commodity',
            'nlKind',
            'blankOrder',
            'products',
            'purchaseOrders',
            'brands',
            'attachments',
            'packingDepartmentNo',
            'asosDepartment',
            'packingSection',
            'packType',
            'boxType',
            'costDated',
            'costBasedOn',
            'htsCode',
            'BDCommission',
            'otherCommission',
            'orderComment',
            'specialComments'
        ),
    (state, mode) => mode,
    state => state,
    (
        {
            type,
            isTBC,
            retailer,
            noOfPacks,
            department,
            echoOrderNo,
            versionNo,
            orderAmendedDate,
            orderPlacedDate,
            sampleSendDate,
            licensor,
            styleNo,
            productDescription,
            factory,
            factoryRef,
            supplierNo,
            season,
            productionSampleType,
            sendToEcho,
            sendToBuyer,
            systemFactoryNo,
            kimballNo,
            commodity,
            nlKind,
            blankOrder,
            products = [],
            brands = [],
            attachments = [],
            packingDepartmentNo,
            asosDepartment,
            packingSection,
            packType,
            boxType,
            costDated,
            costBasedOn,
            htsCode,
            BDCommission = {},
            otherCommission = {},
            orderComment,
            specialComments
        },
        mode,
        state
    ) => {
        const prevValues =
            orderFormSelector(state, 'lastFullPublishedPayload') || {};
        const amendCheckLastDate = 1670868000000; // 00:00 13 Dec 2022
        const orderAmendTime = new Date(
            deserializeDate(orderAmendedDate)
        ).getTime();

        const shouldAmendCheck =
            mode === 'amend' && orderAmendTime > amendCheckLastDate;
        const amendmentChecker = isFieldAmended(shouldAmendCheck);

        const { poLength, poTable, grandTotal } = reportPODetailsSelector(
            state,
            shouldAmendCheck
        );

        const retailerValue = valueSelector(
            state,
            SAMPLING_DOMAIN_NAME,
            RETAILERS,
            retailer
        );
        const typeValue = valueSelector(
            state,
            SAMPLING_DOMAIN_NAME,
            GARMENTS_TYPE,
            type
        );
        const version = `V${
            mode === 'amend' ? Number(versionNo) + 1 : versionNo
        }`;
        const setup = {
            type: typeValue,
            isTBC,
            retailer: retailerValue,
            noOfPacks
        };
        const details = {
            retailer: retailerValue,
            department: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                DEPARTMENTS,
                department
            ),
            sampleSendDate: {
                value: sampleSendDate
            },
            licensor: {
                value: licensor,
                isAmend: amendmentChecker(prevValues.licensingCompany, licensor)
            },
            echoOrderNo,
            versionNo: {
                value: version,
                isAmend: shouldAmendCheck
            },
            orderAmendedDate: normalizeDate(new Date()),
            orderPlacedDate,
            styleNo: {
                value: styleNo,
                isAmend: amendmentChecker(prevValues.styleNo, styleNo)
            },
            productDescription: {
                value: productDescription,
                isAmend: amendmentChecker(
                    prevValues.productionDescription,
                    productDescription
                )
            },
            factory: {
                value: factoryValueSelector(state, factory),
                isAmend: amendmentChecker(prevValues.orderFactory, factory)
            },
            factoryRef: {
                value: factoryRef,
                isAmend: amendmentChecker(
                    prevValues.orderFactoryRef,
                    factoryRef
                )
            },
            supplierNo: {
                value: supplierNo,
                isAmend: amendmentChecker(
                    prevValues.orderSupplierNo,
                    supplierNo
                )
            },
            season: {
                value: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    SEASONS,
                    season
                ),
                isAmend: amendmentChecker(prevValues.orderSeason, season)
            },
            productionSampleType: {
                value: valueSelector(
                    state,
                    DOMAIN_NAME,
                    PRODUCTION_SAMPLE_TYPE,
                    productionSampleType
                ),
                isAmend: amendmentChecker(
                    prevValues.orderProductionSamples,
                    productionSampleType
                )
            },
            sendToEcho: {
                value: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    GET_SAMPLE_SIZES,
                    sendToEcho
                ),
                isAmend: amendmentChecker(prevValues.ukSize, sendToEcho)
            },
            sendToBuyer: {
                value: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    GET_SAMPLE_SIZES,
                    sendToBuyer
                ),
                isAmend: amendmentChecker(prevValues.buyerSize, sendToBuyer)
            },
            systemFactoryNo: {
                value: systemFactoryNo,
                isAmend: amendmentChecker(prevValues.sysFacNo, systemFactoryNo)
            },
            kimballNo: {
                value: kimballNo,
                isAmend: amendmentChecker(prevValues.kimbelNo, kimballNo)
            },
            commodity: {
                value: commodity,
                isAmend: amendmentChecker(prevValues.commodity, commodity)
            },
            nlKind: {
                value: nlKind,
                isAmend: amendmentChecker(prevValues.nlKind, nlKind)
            },
            blankOrder
        };
        const other = {
            brands,
            attachments,
            packingDepartmentNo: {
                value: packingDepartmentNo,
                isAmend: amendmentChecker(
                    prevValues.departmentNo,
                    packingDepartmentNo
                )
            },
            asosDepartment: {
                value: asosDepartment,
                isAmend: amendmentChecker(
                    prevValues.asosDepartmentName,
                    asosDepartment
                )
            },
            packingSection: {
                value: packingSection,
                isAmend: amendmentChecker(
                    prevValues.sectionDescription,
                    packingSection
                )
            },
            packType: {
                value: valueSelector(
                    state,
                    DOMAIN_NAME,
                    PACKING_PACK_TYPE,
                    packType
                ),
                isAmend: amendmentChecker(prevValues.packType, packType)
            },
            boxType: {
                value: valueSelector(
                    state,
                    DOMAIN_NAME,
                    PACKING_BOX_TYPE,
                    boxType
                ),
                isAmend: amendmentChecker(prevValues.boxType, boxType)
            },
            costDated: {
                value: costDated,
                isAmend: amendmentChecker(
                    normalizeDate(prevValues.costingDate),
                    costDated
                )
            },
            costBasedOn: {
                value: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    DEVELOPMENT_TYPES,
                    costBasedOn
                ),
                isAmend: amendmentChecker(prevValues.costBasedOn, costBasedOn)
            },
            htsCode: {
                value: htsCode,
                isAmend: amendmentChecker(prevValues.htsCode, htsCode)
            },
            BDCommission: {
                value: combinedGroupValue(BDCommission, true),
                isAmend: amendmentChecker(
                    combinedGroupValue(prevValues.bdCommission, true),
                    combinedGroupValue(BDCommission, true)
                )
            },
            otherCommission: {
                value: combinedGroupValue(otherCommission, true),
                isAmend: amendmentChecker(
                    combinedGroupValue(prevValues.otherCommission, true),
                    combinedGroupValue(otherCommission, true)
                )
            },
            orderComment,
            specialComments
        };

        return {
            pageTitle: `${
                mode === 'amend' ? 'AMEND' : 'NEW'
            } ${typeValue} ORDER - ${retailerValue} - ${echoOrderNo} - ${factoryRef} ${version}`,
            docTitle: `${echoOrderNo}_${factoryRef}_${version}_order_report.pdf`,
            setup,
            details,
            other,
            products: distinctProductsSelector(products),
            poLength,
            poTable,
            grandTotal
        };
    }
);

const orderMailSubjectSelector = createSelector(
    state => orderFormSelector(state, 'echoOrderNo', 'versionNo', 'factoryRef'),
    (state, mode) => mode,
    (state, mode, statusActionName) => statusActionName,
    ({ echoOrderNo, versionNo, factoryRef }, mode, statusActionName) => {
        const version =
            mode === 'amend'
                ? `V${Number(versionNo) + 1}`
                : `V${Number(versionNo)}`;
        if (statusActionName) {
            return `${statusActionName} ORDER - ${echoOrderNo} ${version} ${factoryRef}`;
        }
        return `${
            mode === 'amend' ? 'Amend' : 'New'
        } ORDER - ${echoOrderNo} ${version} ${factoryRef}`;
    }
);

const amendOrdersSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, AMEND_ORDERS),
    orders => orders
);

const canGenerateSKUSelector = createSelector(
    state => POFormSelector(state, 'sizeDetails') || [],
    sizeDetails => {
        const hasQuantityWithEmptySKU = sizeDetails.some(
            ({ quantity, sku }) => quantity > 0 && !sku
        );

        const givenSKU = sizeDetails.filter(({ sku }) => !!sku);

        const onlyNumbers = givenSKU.every(({ sku }) =>
            /^[0-9]+$/.test(sku.trim())
        );

        return hasQuantityWithEmptySKU && givenSKU.length === 1 && onlyNumbers;
    }
);

const columnGroupsSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, COLUMN_GROUP),
    (state, retailer) => retailer,
    (columnGroups, retailer = '') => {
        return columnGroups
            .filter(({ retailerId }) => Number(retailerId) === retailer)
            .map(({ groupId, groupName }) => ({
                label: groupName,
                value: groupId
            }));
    }
)((state, retailer) => `columnGroups-${retailer}`);

const orderListsUniqueRetailerSelector = createSelector(
    state => entitySelector(state, GRID_VIEW_DOMAIN_NAME, '@orderLists'),
    ({ data } = {}) => {
        if (data && data.length > 0) {
            const retailers = data
                .filter(({ retailerId = {} }) => !!retailerId.value)
                .map(({ retailerId }) => retailerId.value);
            const isUniqueRetailer = retailers.every(
                val => val === retailers[0]
            );
            return isUniqueRetailer ? Number(retailers[0]) : undefined;
        }
    }
);

const getPOSizeTableData = data => data;

const getPOSizeTableHeadersSelector = createSelector(
    getPOSizeTableData,
    data => {
        const properties = Object.keys(data[0] || {});

        const headers = properties
            .map(property => {
                switch (property) {
                    case 'sizeName':
                        return {
                            header: 'Size',
                            name: property,
                            serial: 1
                        };
                    case 'quantity':
                        return {
                            header: 'Quantity',
                            name: property,
                            serial: 2
                        };
                    case 'packQuantity':
                        return {
                            header: 'Pack Quantity',
                            name: property,
                            serial: 3
                        };
                    case 'ratio':
                        return {
                            header: 'Ratio',
                            name: property,
                            serial: 4
                        };
                    case 'packRatio':
                        return {
                            header: 'Pack Ratio',
                            name: property,
                            serial: 5
                        };
                    case 'sku':
                        return { header: 'SKU', name: property, serial: 6 };
                    case 'barCode':
                        return {
                            header: 'Bar Code',
                            name: property,
                            serial: 7
                        };
                    default:
                        return null;
                }
            })
            .filter(value => value)
            .sort((a, b) => a.serial - b.serial)
            .map(({ serial, ...rest }) => rest);

        return headers;
    }
);

const getPOSizeDataSummarySelector = createSelector(
    getPOSizeTableData,
    getPOSizeTableHeadersSelector,
    (data, headers) => {
        const footerData = {};

        headers.forEach(({ name }) => {
            switch (name) {
                case 'sizeName':
                    footerData[name] = 'Total';
                    break;
                case 'quantity':
                case 'packQuantity':
                case 'ratio':
                case 'packRatio':
                    footerData[name] = data.reduce(
                        (total, item) => total + (item[name] || 0),
                        0
                    );
                    break;
                case 'sku':
                case 'barCode':
                    footerData[name] = '';
                    break;
                default:
                    footerData[name] = '';
                    break;
            }
        });

        return footerData;
    }
);

const orderGridPreviewPODetailsSelector = createSelector(
    poDetails => poDetails,
    (_, retailerId) => Number(retailerId),
    (_, __, orderPack) => Number(orderPack),
    (poDetails, retailerId, noOfPacks) => {
        const isAsos = retailerId === ASOS_ID;
        const isNinetyPercent = retailerId === NINETY_PERCENT_ID;
        const isPrimark = retailerId === PRIMARK_ID;
        const isRab = retailerId === RAB_ID;
        const isPrimarkOrRab = isPrimark || isRab;

        const { sizeWiseTotal = {}, ...preparedPOTable } = poDetails.reduce(
            (
                acm,
                {
                    pono: poNo,
                    packId: poPackId,
                    poTypeName: orderType,
                    poModeName: shipMode,
                    poDestinationName: shipDestination,
                    poPackType: packType,
                    exFtyDate: estimateFactoryDate,
                    fobDate: freeOnBoardDate,
                    retailPrice,
                    quantity: orderQuantity,
                    totalPack: totalRatio,
                    styleNo: orderStyle,
                    labDetails: dips,
                    sizeDetails,
                    discount = ''
                },
                idx
            ) => {
                const preparedDips = dips.reduce(
                    (
                        acm,
                        {
                            sampleRef,
                            batchRef: batchConfirmation,
                            colors: color,
                            colorCode = '',
                            orderSustainability: sustainability,
                            colorNote: colorRecipe = '',
                            fobPrice,
                            composition,
                            fabricType,
                            fabricGsm: gsm
                        }
                    ) => {
                        const fabrication = composition
                            ? `${composition} / ${fabricType} / ${gsm}`
                            : null;

                        const fobPriceValue = combinedGroupValue(fobPrice);
                        return {
                            'Sam Ref': acm.hasOwnProperty('Sam Ref')
                                ? [...acm['Sam Ref'], { value: sampleRef }]
                                : [{ value: sampleRef }],
                            ...(isNinetyPercent
                                ? {
                                      'Batch Ref': acm['Batch Ref']
                                          ? [
                                                ...acm['Batch Ref'],
                                                { value: batchConfirmation }
                                            ]
                                          : [{ value: batchConfirmation }]
                                  }
                                : {}),
                            Colour: acm.hasOwnProperty('Colour')
                                ? [...acm['Colour'], { value: color }]
                                : [{ value: color }],
                            'Colour Code': acm.hasOwnProperty('Colour Code')
                                ? [...acm['Colour Code'], { value: colorCode }]
                                : [{ value: colorCode }],
                            'Lab Dip': acm.hasOwnProperty('Lab Dip')
                                ? [...acm['Lab Dip'], { value: colorRecipe }]
                                : [{ value: colorRecipe }],
                            ...(isAsos
                                ? {
                                      Sustainability: acm.hasOwnProperty(
                                          'Sustainability'
                                      )
                                          ? [
                                                ...acm['Sustainability'],
                                                { value: sustainability }
                                            ]
                                          : [{ value: sustainability }]
                                  }
                                : {}),
                            ...(isAsos
                                ? {
                                      Fabrication: acm['Fabrication']
                                          ? [
                                                ...acm['Fabrication'],
                                                { value: fabrication }
                                            ]
                                          : [{ value: fabrication }]
                                  }
                                : {}),
                            'FOB Price in PC': acm.hasOwnProperty(
                                'FOB Price in PC'
                            )
                                ? [
                                      ...acm['FOB Price in PC'],
                                      { value: fobPriceValue }
                                  ]
                                : [{ value: fobPriceValue }]
                        };
                    },
                    {}
                );
                const combinedRetailPrice = combinedGroupValue(retailPrice);
                const priceOfRetail = isAsos
                    ? null
                    : combinedRetailPrice
                    ? combinedRetailPrice
                    : 'N/A';

                const types = getPOSizeTableHeadersSelector(sizeDetails)
                    .map(({ name, header }) => {
                        if (name === 'sizeName') return null;
                        if (name === 'quantity') {
                            return {
                                value:
                                    noOfPacks === 1 ? 'Qty in Pcs' : 'Pack Qty'
                            };
                        }

                        if (name === 'packQuantity') {
                            return noOfPacks === 1
                                ? null
                                : {
                                      value: 'Qty in Pcs'
                                  };
                        }

                        if (name === 'ratio') {
                            return packType === 'Normal'
                                ? null
                                : { value: header };
                        }

                        if (name === 'packRatio') {
                            return packType === 'Ratio' && noOfPacks > 1
                                ? {
                                      value: header
                                  }
                                : null;
                        }

                        if (name === 'barCode') {
                            return isNinetyPercent ? { value: header } : null;
                        }

                        return {
                            value: header
                        };
                    })
                    .filter(value => value);

                const sizes = sizeDetails.reduce(
                    (acm, { sizeName, locked, ...values }, idx) => {
                        let sizeValues = values;

                        return {
                            ...acm,
                            [sizeName]: Object.entries(sizeValues)
                                .map(([key, value]) => {
                                    switch (key) {
                                        case 'quantity':
                                            return {
                                                value,
                                                serial: 1
                                            };
                                        case 'packQuantity':
                                            return noOfPacks === 1
                                                ? null
                                                : {
                                                      value,
                                                      serial: 2
                                                  };
                                        case 'ratio':
                                            return packType === 'Normal'
                                                ? null
                                                : {
                                                      value,
                                                      serial: 3
                                                  };
                                        case 'packRatio':
                                            return noOfPacks === 1 ||
                                                packType === 'Normal'
                                                ? null
                                                : {
                                                      value,
                                                      serial: 4
                                                  };
                                        case 'sku':
                                            return {
                                                value,
                                                serial: 5
                                            };
                                        case 'barCode':
                                            return !isNinetyPercent
                                                ? null
                                                : {
                                                      value,
                                                      serial: 6
                                                  };
                                        default:
                                            return null;
                                    }
                                })
                                .filter(value => value)
                                .sort((a, b) => a.serial - b.serial)
                                .map(({ serial, ...rest }) => rest)
                        };
                    },
                    {}
                );

                const poWiseTotal = Object.entries(
                    getPOSizeDataSummarySelector(sizeDetails)
                )
                    .map(([key, value]) => {
                        if (key === 'sizeName') return null;
                        if (noOfPacks === 1 && key === 'packQuantity')
                            return null;
                        if (
                            packType === 'Normal' &&
                            (key === 'ratio' || key === 'packRatio')
                        )
                            return null;
                        if (noOfPacks === 1 && key === 'packRatio') return null;
                        if (key === 'barCode' && !isNinetyPercent) return null;
                        return {
                            value
                        };
                    })
                    .filter(value => value);

                const sizeWiseTotal = sizeDetails.reduce(
                    (totalAcm, { sizeName, packQuantity }) => {
                        const prevValue = acm.hasOwnProperty('sizeWiseTotal')
                            ? acm.sizeWiseTotal[sizeName]
                            : 0;
                        return {
                            ...totalAcm,
                            [sizeName]: prevValue + Number(packQuantity)
                        };
                    },
                    {}
                );

                const packQuantity = totalRatio
                    ? orderQuantity / totalRatio
                    : 0;

                return {
                    ...(idx === 0
                        ? {
                              keys: [
                                  'PO No',
                                  ...(isPrimarkOrRab
                                      ? [isPrimark ? 'Pack ID' : 'Dest. ID']
                                      : []),
                                  ...(isAsos ? ['Option ID'] : []),
                                  ...Object.keys(preparedDips),
                                  ...(isAsos
                                      ? ['FOB in Pack(after disc)']
                                      : []),
                                  'Mode',
                                  'Dest.',
                                  'PO Type',
                                  'Ex. Fty',
                                  'Delivery',
                                  ...(priceOfRetail ? ['Rt Pr.'] : []),
                                  'Qty.',
                                  ...(packType === 'Ratio'
                                      ? ['Total Pack Qty.']
                                      : []),
                                  'Total Pack Qty.',
                                  'Type',
                                  ...Object.keys(sizes),
                                  'Total'
                              ]
                          }
                        : acm),
                    values: [
                        ...acm.values,
                        [
                            { value: poNo },
                            ...(isPrimarkOrRab ? [{ value: poPackId }] : []),
                            ...(isAsos ? [{ value: orderStyle }] : []),
                            ...Object.values(preparedDips),
                            ...(isAsos
                                ? [
                                      {
                                          value: discountSelector(
                                              dips,
                                              discount
                                          ).fobDiscount
                                      }
                                  ]
                                : []),
                            { value: shipMode },
                            { value: shipDestination },
                            { value: orderType },
                            { value: normalizeDate(estimateFactoryDate) },
                            { value: normalizeDate(freeOnBoardDate) },
                            ...(priceOfRetail
                                ? [{ value: priceOfRetail }]
                                : []),
                            { value: orderQuantity },
                            ...(packType === 'Ratio'
                                ? [
                                      {
                                          value: orderQuantity / totalRatio
                                      }
                                  ]
                                : []),
                            {
                                value: packQuantity
                            },
                            types,
                            ...Object.values(sizes),
                            poWiseTotal
                        ]
                    ],
                    sizeWiseTotal
                };
            },
            { keys: [], values: [] }
        );

        sizeWiseTotal.grandTotal = Object.values(sizeWiseTotal).reduce(
            (acm, curr) => acm + curr,
            0
        );

        return {
            poLength: poDetails.length,
            poTable: preparedPOTable,
            grandTotal: {
                keys: Object.keys(sizeWiseTotal),
                values: Object.values(sizeWiseTotal)
            }
        };
    }
);

const orderGridPreviewDataSelector = createCachedSelector(
    data => data,
    (_, orderNo) => orderNo,
    (data, orderNo) => {
        if (!data || Object.entries(data).length === 0) {
            return {};
        }

        if (data.orderSupplierRef === orderNo) {
            const {
                type,
                isTbcSample,
                retailer,
                retailerId,
                orderPack,
                department,
                sampleEta,
                orderSupplierRef,
                orderVersionNo,
                orderAmendDate,
                orderDate,
                styleNo,
                productionDescription,
                orderFactory,
                orderFactoryRef,
                orderSupplierNo,
                orderSeason,
                orderProductionSamples,
                buyerSize,
                ukSize,
                sysFacNo,
                kimbelNo,
                commodity,
                nlKind,
                orderLabelings,
                departmentNo,
                asosDepartmentName,
                sectionDescription,
                licensingCompany,
                packType,
                boxType,
                costingDate,
                costBasedOn,
                htsCode,
                bdCommission = {},
                otherCommission = {},
                orderComments,
                specialorderComments,
                orderAttachments = [],
                orderPoDetails = [],
                orderSamples = [],
                reportLink,
                softPublish
            } = data;
            const attachments = orderAttachments;
            const versionNo = `V${orderVersionNo}`;
            const emailSubject = `ORDER - ${orderSupplierRef} ${versionNo} ${orderFactoryRef}`;
            const previewTitle = `Order Preview - ${orderNo}`;
            if (!softPublish && reportLink) {
                return {
                    emailSubject,
                    attachments,
                    storedPDFData: {
                        url: reportLink,
                        filename: deserializeFilename(reportLink)
                    },
                    previewTitle,
                    orderVersionNo
                };
            }
            const typeValue = type === 'P' ? 'PRINT' : 'SHAPE';
            const {
                poLength,
                poTable,
                grandTotal
            } = orderGridPreviewPODetailsSelector(
                orderPoDetails,
                retailerId,
                orderPack
            );
            return {
                setup: {
                    type: typeValue,
                    isTBC: isTbcSample,
                    retailer,
                    noOfPacks: orderPack
                },
                details: {
                    retailer,
                    department,
                    sampleSendDate: { value: normalizeDate(sampleEta) },
                    licensor: { value: licensingCompany },
                    echoOrderNo: orderSupplierRef,
                    versionNo: { value: versionNo },
                    orderAmendedDate: normalizeDate(orderAmendDate),
                    orderPlacedDate: normalizeDate(orderDate),
                    styleNo: { value: styleNo },
                    productDescription: { value: productionDescription },
                    factory: { value: orderFactory },
                    factoryRef: { value: orderFactoryRef },
                    supplierNo: { value: orderSupplierNo },
                    season: { value: orderSeason },
                    productionSampleType: { value: orderProductionSamples },
                    sendToEcho: { value: ukSize },
                    sendToBuyer: { value: buyerSize },
                    systemFactoryNo: { value: sysFacNo },
                    kimballNo: { value: kimbelNo },
                    commodity: { value: commodity },
                    nlKind: { value: nlKind }
                },
                other: {
                    brands: orderLabelings,
                    packingDepartmentNo: { value: departmentNo },
                    asosDepartment: { value: asosDepartmentName },
                    packingSection: { value: sectionDescription },
                    packType: { value: packType },
                    boxType: { value: boxType },
                    costDated: { value: normalizeDate(costingDate) },
                    costBasedOn: { value: costBasedOn },
                    htsCode: { value: htsCode },
                    BDCommission: { value: combinedGroupValue(bdCommission) },
                    otherCommission: {
                        value: combinedGroupValue(otherCommission)
                    },
                    orderComment: orderComments,
                    specialComments: specialorderComments
                },
                products: distinctProductsSelector(orderSamples),
                poLength,
                poTable,
                grandTotal,
                downloadAbleAttachments: orderAttachments,
                pageTitle: `${typeValue} ORDER - ${retailer} - ${orderSupplierRef} - ${orderFactoryRef} ${versionNo}`,
                docTitle: `${orderSupplierRef}_${orderFactoryRef}_${versionNo}_order_report.pdf`,
                emailSubject,
                orderVersionNo,
                hasWatermark: !!softPublish,
                watermarkText: softPublish ? 'soft publish' : null
            };
        }
        return {};
    }
)((data, orderNo) => `order-preview-${orderNo}`);

const orderCPExportExcelDataLoadingSelector = createSelector(
    ordersSelector,
    ({ orderCPExportExcelDataLoading }) => orderCPExportExcelDataLoading
);

export {
    isNinetyPercentSelector,
    isPrimarkSelector,
    isAsosSelector,
    isNewlookSelector,
    clientPOTBCLoadingSelector,
    productsLoadingSelector,
    sampleRefChangeDataLoadingSelector,
    orderNoLoadingSelector,
    factoryRefLoadingSelector,
    labDipsSelector,
    distinctProductsSelector,
    colorsDataSelector,
    labDipColorLoadingSelector,
    poTBCLoadingSelector,
    paymentAndSettlementDeafaultValueLoadingSelector,
    poPackTBCLoadingSelector,
    poSizeSetLoadingSelector,
    isPackTypeRatioSelector,
    PONoCheckLoadingSelector,
    orderTrimsLoadingSelector,
    costBasedOnSelector,
    presetCommentsSelector,
    reportPropsSelector,
    orderMailSubjectSelector,
    amendOrdersSelector,
    discountSelector,
    batchRefsDataSelector,
    sampleSendDateSelector,
    canGenerateSKUSelector,
    columnGroupsSelector,
    orderListsUniqueRetailerSelector,
    isSeasaltSelector,
    getOrderFormErrorsSelector,
    getPOFormErrorsSelector,
    getPOSizeTableHeadersSelector,
    getPOSizeDataSummarySelector,
    orderGridPreviewDataSelector,
    orderCPExportExcelDataLoadingSelector,
    isRabSelector
};
