import { useEffect, useState } from 'react';

import getDataUrl from '@libs/getDataUrl';
import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDFContent from './PDFContent';

export const loadProps = async ({
    values: { dispatchDetails, ...restValues },
    ...restProps
}) => {
    const dispatchDetailsImageResolving = await Promise.all(
        dispatchDetails.map(async ({ sampleImage, ...restDispatchDetails }) => {
            return {
                sampleImage: !!sampleImage
                    ? await getDataUrl(sampleImage)
                    : undefined,
                ...restDispatchDetails
            };
        })
    );

    return {
        values: {
            dispatchDetails: dispatchDetailsImageResolving,
            ...restValues
        },
        ...restProps
    };
};

export const useMakePDF = ({ props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleClick, loading, disabled, PDF, handleRemove] = usePDF({
        document: PDFContent,
        fileName: 'SampleDispatchReport.pdf',
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true,
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleClick
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleClick, PDF, handleRemove, loading];
};
