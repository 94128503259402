import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, withStyles, CircularProgress } from '@material-ui/core';

import Button from '@libComponents/Button';
// Local
import usePurchaseOrders from './usePurchaseOrders';
import Card from './Card';
import { usePOLoadMore } from './hooks';

const propTypes = {
    handleModal: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    watchPORemoved: PropTypes.func
};

const defaultProps = {};

const styles = ({ palette, spacing, typography, shadows }) => ({
    content: {
        padding: spacing.unit * 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.unit * 10
    },
    emptyContent: {
        color: palette.secondary.light
    },
    button: {
        height: '100%',
        marginTop: spacing.unit * 2,
        color: palette.common.black,
        fontSize: spacing.unit * 2,
        fontWeight: typography.fontWeightSemi,
        background: palette.grey[300],
        borderRadius: 0,
        textTransform: 'uppercase',
        alignSelf: 'center',
        opacity: 0.8,
        boxShadow: shadows[1],
        '&:hover': {
            opacity: 1
        }
    }
});

const Content = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { spacing },
        handleModal,
        loading,
        watchPORemoved
    }) => {
        const [
            { purchaseOrders, queryValue, isPrimark, isRab },
            dispatch
        ] = usePurchaseOrders();

        const isPrimarkOrRab = isPrimark || isRab;

        const { data, nextPage, handleLoadMore } = usePOLoadMore(
            purchaseOrders,
            queryValue
        );

        const makeHandleDelete = useCallback(
            (poNo, poPackId) => () => {
                const purchaseOrdersCopy = [...purchaseOrders];
                const purchaseOrderIndex = purchaseOrdersCopy.findIndex(
                    po => po.poNo === poNo && po.poPackId === poPackId
                );
                purchaseOrdersCopy.splice(purchaseOrderIndex, 1);
                dispatch({
                    type: 'deletePurchaseOrder',
                    payload: purchaseOrdersCopy
                });
                watchPORemoved(purchaseOrderIndex);
            },
            [purchaseOrders, watchPORemoved]
        );

        const handleEdit = useCallback(
            (poNo, poPackId) => values => {
                dispatch({
                    type: 'editPurchaseOrder',
                    payload: { poNo, values, poPackId }
                });
            },
            []
        );

        const handleDuplicate = useCallback(values => {
            dispatch({
                type: 'duplicatePurchaseOrder',
                payload: values
            });
        }, []);

        const makeHandleEdit = useCallback(
            (poNo, poPackId) => () => {
                const { orderStyle, ...initialValues } = data.find(
                    po => po.poNo === poNo && po.poPackId === poPackId
                );
                return handleModal({
                    handleSave: handleEdit(poNo, poPackId),
                    initialValues: {
                        ...initialValues,
                        orderStyle,
                        prevOrderStyle: orderStyle,
                        isEdit: true
                    }
                });
            },
            [handleModal, data]
        );

        const makeLabDipIdNullForDuplicate = useCallback(
            dips => dips.map(({ id, ...rest }) => ({ id: null, ...rest })),
            []
        );

        const makeHandleDuplicate = useCallback(
            (poNo, poPackId) => () => {
                const { dips, orderStyle, ...initialValues } = data.find(
                    po => po.poNo === poNo && po.poPackId === poPackId
                );
                return handleModal({
                    handleSave: handleDuplicate,
                    initialValues: {
                        ...initialValues,
                        dips: makeLabDipIdNullForDuplicate(dips),
                        poNo: isPrimarkOrRab ? poNo : '',
                        orderStyle,
                        prevOrderStyle: orderStyle,
                        poTBC: false,
                        poNoAsync: '',
                        poPackId: '',
                        packIdAsync: '',
                        packTBC: false,
                        isEdit: false,
                        id: null
                    }
                });
            },
            [handleModal, data, isPrimarkOrRab]
        );

        return (
            <>
                {!!data.length ? (
                    <div className={c.content}>
                        {loading && (
                            <div className={c.loader}>
                                <CircularProgress size={spacing.unit * 5.5} />
                            </div>
                        )}
                        {!loading &&
                            data.map((po, idx) => {
                                const { poNo, poPackId } = po;
                                return (
                                    <Card
                                        {...po}
                                        key={`po-card-${poNo}-${poPackId}-${idx}`}
                                        handleDuplicate={makeHandleDuplicate(
                                            poNo,
                                            poPackId
                                        )}
                                        handleEdit={makeHandleEdit(
                                            poNo,
                                            poPackId
                                        )}
                                        handleDelete={makeHandleDelete(
                                            poNo,
                                            poPackId
                                        )}
                                    />
                                );
                            })}
                        {!loading && nextPage && (
                            <Button
                                color='success'
                                onClick={handleLoadMore}
                                className={c.button}
                                variant='text'
                            >
                                Show More
                            </Button>
                        )}
                    </div>
                ) : (
                    <div className={c.emptyContainer}>
                        <Typography className={c.emptyContent}>
                            {queryValue
                                ? 'No results found!'
                                : 'No PO added yet!'}
                        </Typography>
                    </div>
                )}
            </>
        );
    }
);

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
