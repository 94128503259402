import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    PortalModalContainer,
    PortalModalContent,
    PortalModalHeader
} from '@libComponents/PortalModal';
import sanitizeHTML from '@libs/sanitizeHTML';

const propTypes = {
    onModalClose: PropTypes.func,
    text: PropTypes.string
};

const defaultProps = {
    loading: false,
    onModalClose: f => f
};

const RichTextModalModal = ({ onModalClose, columnHeader, text }) => {
    const sanitizedHtml = useMemo(() => sanitizeHTML(text), [text]);
    return (
        <PortalModalContainer isOpen size='sm'>
            <PortalModalHeader onClose={onModalClose}>
                {columnHeader}
            </PortalModalHeader>
            <PortalModalContent>
                <div
                    style={{ paddingLeft: 16, paddingRight: 16 }}
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                />
            </PortalModalContent>
        </PortalModalContainer>
    );
};

RichTextModalModal.propTypes = propTypes;
RichTextModalModal.defaultProps = defaultProps;

export default RichTextModalModal;
