import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import {
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets,
    createFetchGridDataset,
    gridDatasetSelector
} from '@libs/datasets';
import { userIdSelector } from 'auth/redux.selectors';

import DataGrid from 'dataGrid';
import { dataGridSettingsSelector } from 'personalizationSettings/redux.selectors';
import { setDataGridSettings } from 'personalizationSettings/redux.actions';

import { SEAL_SAMPLE_GRID_DATA } from '../redux.datasets';
import { DOMAIN_NAME } from '../common';
import { MODULE_NAME } from './common';
import { DATASETS, getColumns } from './columns';
import ExportOptions from './components/ExportOptions';

const mapState = state => ({
    storedDataGridSettings: dataGridSettingsSelector(state, MODULE_NAME),
    datasetSelector: (domain, entity) => datasetSelector(state, domain, entity),
    datasetLoadingSelector: (domain, entity) =>
        datasetLoadingSelector(state, domain, entity),
    valueSelector: (domain, entity, value) =>
        valueSelector(state, domain, entity, value),
    dataGridLoading: datasetLoadingSelector(
        state,
        DOMAIN_NAME,
        SEAL_SAMPLE_GRID_DATA
    ),
    userId: userIdSelector(state),
    ...gridDatasetSelector(state, DOMAIN_NAME, SEAL_SAMPLE_GRID_DATA)
});

const mapDispatchToProps = dispatch => ({
    fetchGridData: payload =>
        dispatch(
            createFetchGridDataset({
                endpoint: 'QcPreSealSamples/GetQcDataView',
                domain: DOMAIN_NAME,
                entity: SEAL_SAMPLE_GRID_DATA
            })(payload)
        ),
    fetchDatasets: () => dispatch(fetchDatasets(DATASETS)),
    setDataGridSettings: payload =>
        dispatch(setDataGridSettings(MODULE_NAME)(payload))
});

const SealSampleView = connect(
    mapState,
    mapDispatchToProps
)(({ fetchDatasets, ...rest }) => {
    const columns = useMemo(() => getColumns(), []);

    useEffect(() => {
        fetchDatasets();
    }, []);

    return (
        <DataGrid
            columns={columns}
            valueSelector={valueSelector}
            rowHeight={50}
            RenderDataGridExportOptions={ExportOptions}
            {...rest}
        />
    );
});

export default SealSampleView;
