import { takeLatest, put, call } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { gridXLFileDownload } from '@libs/sagaWorkers';

import { SEAL_SAMPLE_EXPORT_AS_EXCEL } from '../../redux.actionTypes';
import { setSealSampleExportAsExcelLoading } from '../../redux.actions';

function* exportXLDataWorker({ payload }) {
    const rowThreshold = 500;
    const { totalCount, selectedRows } = payload || {};

    if (
        totalCount <= rowThreshold ||
        (!!selectedRows.length && selectedRows.length <= rowThreshold)
    ) {
        try {
            yield put(setSealSampleExportAsExcelLoading(true));
            yield call(gridXLFileDownload, {
                payload,
                fileName: 'sealSample.xlsx',
                endpoint: 'FileUpload/ExportToExcelAllQcsColumn'
            });
            yield put(setSealSampleExportAsExcelLoading(false));
        } catch (error) {
            yield put(setSealSampleExportAsExcelLoading(false));
            yield put(
                setSnack({
                    message: 'Failed to download the Excel file.',
                    type: 'error',
                    duration: 6000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Row count exceeds ${rowThreshold}. Please narrow your search.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

// Watchers
export default function*() {
    yield takeLatest(SEAL_SAMPLE_EXPORT_AS_EXCEL, exportXLDataWorker);
}
