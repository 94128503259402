import React from 'react';

import { IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing, palette }) => ({
    navigation: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'space-between'
    },
    navigationButton: {
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit * 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        backgroundColor: palette.primary.contrastText,
        padding: spacing.unit * 0.5,
        '&:hover': {
            backgroundColor: palette.grey[300]
        }
    }
});

const NavigationControls = ({ onPrev, onNext, classes }) => (
    <div className={classes.navigation}>
        <IconButton className={classes.navigationButton} onClick={onPrev}>
            <ArrowBack />
        </IconButton>
        <IconButton className={classes.navigationButton} onClick={onNext}>
            <ArrowForward />
        </IconButton>
    </div>
);

export default withStyles(styles)(NavigationControls);
