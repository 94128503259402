import React from 'react';

import {
    RETAILERS,
    DEPARTMENTS,
    CURRIER,
    SAMPLE_FACTORY,
    DISPATCH_TYPE,
    DISPATCH_SIZE,
    COUNTRIES
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import ReferenceLinkModal from './components/ReferenceLinkModal';
import DispatchItems from './components/DispatchItems';

export const sampleDispatchDatasets = [
    RETAILERS,
    DEPARTMENTS,
    CURRIER,
    SAMPLE_FACTORY,
    DISPATCH_TYPE,
    DISPATCH_SIZE,
    COUNTRIES
];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number'
        },
        {
            name: 'dispatchRef',
            header: 'Dispatch Ref',
            filterable: true,
            type: 'text',
            render: ({ column, row }) => (
                <ReferenceLinkModal
                    dispatchRef={row[column.name]}
                    currentVersion={row.versionNo}
                />
            )
        },
        {
            name: 'versionNo',
            header: 'Version No',
            type: 'number',
            render: ({ column, row }) => `V${row[column.name]}`
        },
        {
            name: 'currierId',
            header: 'Courier',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: CURRIER
        },
        {
            name: 'trackingRef',
            header: 'Tracking Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: RETAILERS
        },
        {
            name: 'notifyFao',
            header: 'FAO',
            type: 'text'
        },
        {
            name: 'dispatchDate',
            header: 'Actual Send Date',
            filterable: true,
            type: 'date'
        },
        {
            name: 'eta',
            header: 'ETA',
            filterable: true,
            type: 'date'
        },
        {
            name: 'actRcvdDt',
            header: 'Actual Received Date',
            editable: true,
            type: 'date'
        },
        {
            name: 'originCountryId',
            header: 'Source Country',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'destinationCountryId',
            header: 'Destination Country',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'details',
            header: 'Dispatch Items',
            type: 'array',
            hideable: false,
            render: ({ column, row }) => (
                <DispatchItems
                    items={row[column.name]}
                    dispatchRef={row.dispatchRef}
                />
            )
        }
    ];
};
