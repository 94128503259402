import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

// Material
import { Grid, withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import { Checkbox, GridItem, Input, Date as DateInput } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import {
    required,
    number,
    validateStringWithSpaces
} from '@libs/validationRules';
import { extractIndexFromAsterisk } from '@libs/extractValue';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';
import { formErrorsSelector } from 'common/redux.selectors';

// Local
import { PO_FORM_NAME, PO_FORM_FIELDS } from '../../../../common';
import {
    setPOLabDipColorChildren,
    setPOTBCChildren,
    setPOPackTBCChildren,
    setPOSizeSetChildren,
    setPackTypeChildren,
    resetSizeDetailsOnQuantityOrRatioChange,
    setPONoChildren,
    setPOExFtyDate,
    setPOShipModeChildren,
    setPOSizeTableQuantityChildren,
    setPOSizeTableRatioChildren
} from 'orders/redux.actions';
import {
    isNinetyPercentSelector,
    poTBCLoadingSelector,
    poPackTBCLoadingSelector,
    labDipColorLoadingSelector,
    isPackTypeRatioSelector,
    PONoCheckLoadingSelector,
    isAsosSelector,
    isNewlookSelector,
    isPrimarkSelector,
    isRabSelector
} from 'orders/redux.selectors';
import asyncValidate from './asyncValidate';
import TotalQuantity from './TotalQuantity';
import RetailPrice from './RetailPrice';
import DipsContainer from './DipsContainer';
import TotalFabric from './TotalFabric';
import TotalYarnPrice from './TotalYarnPrice';
import YarnPrice from './YarnPrice';
import TotalRatio from './TotalRatio';
import SizeDetailsContainer from './SizeDetailsContainer';
import SelectPaymentTerms from './SelectPaymentTerms';
import SelectSettlementDiscount from './SelectSettlementDiscount';
import SelectPackType from './SelectPackType';
import SelectSizeSet from './SelectSizeSet';
import SelectPOShipMode from './SelectPOShipMode';
import SelectPOShipDestination from './SelectPOShipDestination';
import SelectPOType from './SelectPOType';

const propTypes = {
    handleSave: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired
};

const mapState = (state, { initialValues: { isEdit } = {} }) => {
    const isNinetyPercent = isNinetyPercentSelector(state);
    const labDipFirstColorLoading = labDipColorLoadingSelector(
        state,
        'dips[0].color-loading'
    );

    return {
        formErrors: formErrorsSelector(state, PO_FORM_NAME),
        ...formValueSelector(PO_FORM_NAME)(
            state,
            'orderQuantity',
            'packType',
            'totalRatio'
        ),
        labDipFirstColorLoading: isNinetyPercent && labDipFirstColorLoading,
        poTBCLoading: poTBCLoadingSelector(state),
        poPackTBCLoading: poPackTBCLoadingSelector(state),
        isRatio: isPackTypeRatioSelector(state),
        PONoCheckLoading: PONoCheckLoadingSelector(state),
        isEdit: !!isEdit,
        isNinetyPercent,
        isAsos: isAsosSelector(state),
        isNewlook: isNewlookSelector(state),
        isPrimark: isPrimarkSelector(state),
        isRab: isRabSelector(state)
    };
};

const mapInputs = {
    'dips[*].batchConfirmation': ({ payload, meta: { field } }) => {
        return change(
            PO_FORM_NAME,
            `dips[${extractIndexFromAsterisk(field)}].color`,
            ''
        );
    },
    'dips[*].color': ({ payload, meta: { field } }) =>
        setPOLabDipColorChildren({ color: payload, field }),
    poTBC: ({ payload }) => setPOTBCChildren(payload),
    packTBC: ({ payload }) => setPOPackTBCChildren(payload),
    poNo: ({ payload }) => setPONoChildren(payload),
    shipMode: ({ payload }) => setPOShipModeChildren(payload),
    freeOnBoardDate: ({ payload }) => setPOExFtyDate(payload),
    packType: () => setPackTypeChildren(),
    orderQuantity: () => resetSizeDetailsOnQuantityOrRatioChange(),
    totalRatio: () => resetSizeDetailsOnQuantityOrRatioChange(true),
    sizeSet: ({ payload }) => setPOSizeSetChildren(payload),
    'sizeDetails[*].quantity': ({ payload, meta: { field } }) =>
        setPOSizeTableQuantityChildren({ quantity: payload, field }),
    'sizeDetails[*].ratio': ({ payload, meta: { field } }) =>
        setPOSizeTableRatioChildren({ ratio: payload, field })
};

const styles = ({ spacing }) => ({
    content: { width: '90vw' },
    tbcWrapper: {
        position: 'relative'
    },
    tbc: {
        position: 'absolute',
        right: 0,
        top: -2
    },
    checkboxLabel: { fontSize: spacing.unit * 1.5 },
    checkboxIcon: { width: spacing.unit * 2.5, height: spacing.unit * 2.5 },
    button: {
        width: '100%',
        maxWidth: spacing.unit * 75,
        margin: `${spacing.unit * 2}px auto`
    }
});

const fabricConsumptionRules = [required, number];
const PONumberRules = [required, validateStringWithSpaces];

const Modal = compose(
    reduxForm({
        form: PO_FORM_NAME,
        onSubmit: (values, dispatch, { handleSave, onClose }) => {
            handleSave(values);
            onClose();
        },
        asyncValidate,
        asyncBlurFields: ['poNo', 'poPackId']
    }),
    connect(mapState),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleSubmit,
        formErrors,
        orderQuantity,
        packType,
        totalRatio,
        poTBCLoading,
        poPackTBCLoading,
        labDipFirstColorLoading,
        isRatio,
        PONoCheckLoading,
        isEdit,
        isNinetyPercent,
        isAsos,
        isNewlook,
        isPrimark,
        isRab
    }) => {
        const isPrimarkOrRab = isPrimark || isRab;

        const [POFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            PO_FORM_FIELDS,
            PO_FORM_NAME
        );

        const handlePOFormSubmit = useCallback(() => {
            handleSubmit();
            handleScroll();
        }, [handleSubmit, handleScroll]);

        const shouldDisableSizeSet = () => {
            if (packType === 'Normal') {
                return labDipFirstColorLoading || !orderQuantity;
            } else {
                return labDipFirstColorLoading || !totalRatio || !orderQuantity;
            }
        };

        return (
            <>
                <ModalHeader onClose={onClose}>
                    {`${isEdit ? 'Update' : 'Add'} PO Details`}
                </ModalHeader>
                <ModalContent className={c.content}>
                    <div ref={POFormRef}>
                        <DipsContainer />
                        <Grid container>
                            {isAsos && (
                                <GridItem md={2}>
                                    <Input
                                        name='cidNO'
                                        label='CID No'
                                        required
                                    />
                                </GridItem>
                            )}
                            <GridItem md={2} className={c.tbcWrapper}>
                                <Input
                                    name='poNo'
                                    label='PO'
                                    loading={poTBCLoading}
                                    validate={PONumberRules}
                                />
                                <div className={c.tbc}>
                                    <Checkbox
                                        name='poTBC'
                                        label='TBC'
                                        styles={{
                                            label: c.checkboxLabel,
                                            icon: c.checkboxIcon
                                        }}
                                    />
                                </div>
                            </GridItem>

                            {isPrimarkOrRab && (
                                <GridItem md={2} className={c.tbcWrapper}>
                                    <Input
                                        name='poPackId'
                                        label={
                                            isPrimark
                                                ? 'Pack ID'
                                                : 'Destination ID'
                                        }
                                        loading={poPackTBCLoading}
                                        required
                                    />
                                    <div className={c.tbc}>
                                        <Checkbox
                                            name='packTBC'
                                            label='TBC'
                                            styles={{
                                                label: c.checkboxLabel,
                                                icon: c.checkboxIcon
                                            }}
                                        />
                                    </div>
                                </GridItem>
                            )}

                            <GridItem md={2}>
                                {isNinetyPercent ? (
                                    <Input
                                        name='shipMode'
                                        label='Mode'
                                        loading={labDipFirstColorLoading}
                                        disabled
                                    />
                                ) : (
                                    <SelectPOShipMode required />
                                )}
                            </GridItem>
                            <GridItem md={2}>
                                {isNinetyPercent ? (
                                    <Input
                                        name='shipDestination'
                                        label='Destination'
                                        disabled
                                        loading={labDipFirstColorLoading}
                                    />
                                ) : (
                                    <SelectPOShipDestination required />
                                )}
                            </GridItem>
                            <GridItem md={2}>
                                <SelectPaymentTerms />
                            </GridItem>
                            {isAsos && (
                                <GridItem md={2}>
                                    <SelectSettlementDiscount />
                                </GridItem>
                            )}
                            <GridItem md={2}>
                                <DateInput
                                    name='freeOnBoardDate'
                                    label='FOB'
                                    placeholder='fob'
                                    required
                                    loading={labDipFirstColorLoading}
                                />
                            </GridItem>
                            <GridItem md={2}>
                                <DateInput
                                    name='estimateFactoryDate'
                                    label='Ex-Fty'
                                    placeholder='ex-Fty'
                                    required
                                    loading={labDipFirstColorLoading}
                                />
                            </GridItem>
                            <GridItem md={2}>
                                {isNinetyPercent ? (
                                    <Input
                                        name='orderType'
                                        label='Order Type'
                                        disabled
                                    />
                                ) : (
                                    <SelectPOType required={!isAsos} />
                                )}
                            </GridItem>
                            {isAsos && (
                                <>
                                    <GridItem md={2}>
                                        <Input
                                            name='discount'
                                            label='Pack Discount Rate'
                                        />
                                    </GridItem>
                                    <GridItem md={2}>
                                        <Input
                                            name='orderStyle'
                                            label='Option ID'
                                            required
                                        />
                                    </GridItem>
                                </>
                            )}
                            <GridItem md={2}>
                                <TotalQuantity
                                    loading={labDipFirstColorLoading}
                                    disabled={isNinetyPercent}
                                />
                            </GridItem>
                            {isNewlook && (
                                <>
                                    <GridItem md={2}>
                                        <Input
                                            name='fabricConsumption'
                                            label='Fabric Consumption(in KG)'
                                            placeholder='Fabric Consumption'
                                            validate={fabricConsumptionRules}
                                            required
                                            type='number'
                                        />
                                    </GridItem>
                                    <GridItem md={2}>
                                        <Input
                                            name='yarnCount'
                                            label='Yarn Count'
                                            required
                                        />
                                    </GridItem>
                                    <GridItem md={2}>
                                        <YarnPrice />
                                    </GridItem>
                                    <GridItem md={2}>
                                        <TotalFabric />
                                    </GridItem>
                                    <GridItem md={2}>
                                        <TotalYarnPrice />
                                    </GridItem>
                                </>
                            )}
                            {!isAsos && (
                                <GridItem md={2}>
                                    <RetailPrice
                                        required
                                        loading={labDipFirstColorLoading}
                                    />
                                </GridItem>
                            )}
                            <GridItem md={2}>
                                <SelectPackType
                                    disabled={labDipFirstColorLoading}
                                />
                            </GridItem>
                            {isRatio && (
                                <GridItem md={2}>
                                    <TotalRatio />
                                </GridItem>
                            )}
                            <GridItem md={2}>
                                <SelectSizeSet
                                    disabled={shouldDisableSizeSet()}
                                />
                            </GridItem>
                        </Grid>
                        <SizeDetailsContainer />
                    </div>
                </ModalContent>
                <ModalFooter>
                    <Button
                        className={c.button}
                        onClick={handlePOFormSubmit}
                        loading={PONoCheckLoading}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </>
        );
    }
);

Modal.propTypes = propTypes;

export { Modal as default, Modal };
