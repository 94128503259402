import React from 'react';
import { View } from '@react-pdf/renderer';
import { string, array, object } from 'prop-types';

import { Field, Title } from 'components/PDF';
import { useCustomDate } from 'sampling/hooks';
import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = () => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldWidth: {
        width: '15%'
    }
});

const propTypes = {
    brands: array,
    packingDepartmentNo: object,
    asosDepartment: object,
    packingSection: object,
    licensingCompany: object,
    packType: object,
    boxType: object,
    costDated: object,
    costBasedOn: object,
    htsCode: object,
    BDCommission: object,
    otherCommission: object,
    orderComment: string,
    specialComments: string
};

const Other = withPDFStyles(styles)(
    ({
        packingDepartmentNo,
        asosDepartment,
        packingSection,
        packType,
        boxType,
        costDated,
        costBasedOn,
        htsCode,
        BDCommission,
        otherCommission,
        classes: c
    }) => {
        return (
            <View>
                <Title text='Packing & Cost Info' />
                <View style={c.container}>
                    <Field
                        label='Department No'
                        value={packingDepartmentNo.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={packingDepartmentNo.isAmend}
                    />
                    {!!asosDepartment.value && (
                        <Field
                            label='ASOS Dept. Name'
                            value={asosDepartment.value}
                            required={true}
                            style={c.fieldWidth}
                            isAmend={asosDepartment.isAmend}
                        />
                    )}
                    {!!packingSection.value && (
                        <Field
                            label='Section'
                            value={packingSection.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={packingSection.isAmend}
                        />
                    )}
                    <Field
                        label='Pack Type'
                        value={packType.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={packType.isAmend}
                    />
                    <Field
                        label='Box Type'
                        value={boxType.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={boxType.isAmend}
                    />
                    <Field
                        label='Cost Dated'
                        value={useCustomDate(costDated.value)}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={costDated.isAmend}
                    />
                    <Field
                        label='Cost Based On'
                        value={costBasedOn.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={costBasedOn.isAmend}
                    />
                    <Field
                        label='HS Code'
                        value={htsCode.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={htsCode.isAmend}
                    />
                    <Field
                        label='BD Commission'
                        value={BDCommission.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={BDCommission.isAmend}
                    />
                    {!!otherCommission.value && (
                        <Field
                            label='Other Commission'
                            value={otherCommission.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={otherCommission.isAmend}
                        />
                    )}
                </View>
            </View>
        );
    }
);

Other.propTypes = propTypes;

export default Other;
