import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import Select from 'components/Inputs/Select';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'version',
    label: '',
    placeholder: '',
    disabled: false,
    required: false,
    isCancellable: false
};

const styles = ({ spacing }) => ({
    control: {
        backgroundColor: '#fff',
        height: spacing.unit * 4,
        width: spacing.unit * 15
    }
});

const SelectVersions = withStyles(styles)(
    ({ currentVersion, fetchGridPreviewData, classes, ...props }) => {
        const [value, setValue] = useState(currentVersion);

        const data = useMemo(() => {
            if (!currentVersion) return [];
            return Array.from({ length: currentVersion }, (_, i) => i + 1)
                .reverse()
                .map(version => ({ value: version, label: `V${version}` }));
        }, [currentVersion]);

        const handleOnChange = useCallback(
            newValue => {
                if (newValue !== Number(value)) {
                    setValue(newValue);
                    fetchGridPreviewData(newValue);
                }
            },
            [fetchGridPreviewData, value]
        );

        return (
            <Select
                data={data}
                value={value}
                handleOnChange={handleOnChange}
                styles={{ control: classes.control }}
                {...props}
            />
        );
    }
);

SelectVersions.propTypes = propTypes;
SelectVersions.defaultProps = defaultProps;

export default SelectVersions;
